/* ------------------------------------------------------------ *\
    Project
\* ------------------------------------------------------------ */

.project {
    @include breakpoint-down(tablet) {
        display: flex;
        flex-direction: column-reverse;
    }

    .project__head {
        display: flex;
        justify-content: space-between;
        padding-left: 3.4rem;
        margin-bottom: 6.3rem;

        @include breakpoint-down(large-desktop) {
            padding-left: 0;
        }

        @include breakpoint-down(tablet) {
            flex-direction: column;
            margin-bottom: 0;
            margin-top: 1.7rem;
        }
    }

    .project__head h5 {
        max-width: 25%;
        flex: 0 0 25%;
        margin-bottom: 0;
        
        @include breakpoint-down(tablet) {
            display: flex;
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 1.5rem;
        }
    }

    .project__head h5 span {
        display: block;

        @include breakpoint-down(tablet) {
            max-width: 50%;
            flex: 0 0 50%;
        }
    }

    .project__head h2 {
        max-width: 56.9%;
        flex: 0 0 56.9%;

        @include breakpoint-down(tablet) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .project__image {
        @include breakpoint-down(mobile) {
            margin-bottom: 0.3rem;
        }
    }
    
    .project__image a {
        display: block;
        width: 100%;
        height: 100%;
    }

    .project__image img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        
        @include breakpoint-down(mobile) {
	     	height: 232px!important;    
	    }     
        
    }
}

.project + .project {
    margin-top: 6.5rem;

    @include breakpoint-down(tablet) {
        margin-top: 6rem;
    }
}