/* ------------------------------------------------------------ *\
    List contacts
\* ------------------------------------------------------------ */

.list-contacts {
    list-style-type: none;
    padding-left: 0;
    
    li {
        margin-bottom: 2rem;
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    a {
        display: block;
    }
}