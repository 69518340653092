$error_color: red !default;
$error_star_color: $error_color;

// hide top of form error message
.validation_error{
  display: none;
}

//hide individual field error message
.validation_message {
  display: none;
}

// * color
.gform_wrapper .gfield_required {
  color: $error_star_color;
}

.gform_wrapper li.gfield.gfield_error,
.gform_wrapper
  li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
  background-color: transparent;
  border-bottom: none;
  border-top: none;
  box-sizing: border-box;
  margin-bottom: 0px !important;
  padding-bottom: 0px;
  padding-top: 0px;
}

.gform_wrapper div.gfield_description.validation_error {
  color: $error_color;
}

.gform_wrapper .validation_message {
  color: $error_color;
}

.gform_wrapper
  li.gfield_error
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
.gform_wrapper li.gfield_error textarea {
  border: 1px solid $error_color;
}

.gform_wrapper li.gfield_error div.ginput_complex.ginput_container label,
.gform_wrapper li.gfield_error ul.gfield_checkbox,
.gform_wrapper li.gfield_error ul.gfield_radio {
  color: $error_color;
}

.gform_wrapper .gfield_error .gfield_label {
  color: $error_color;
}

.gform_wrapper.gform_validation_error .top_label input.medium,
.gform_wrapper.gform_validation_error .top_label select {
  border: 1px solid $error_color;
}

.gfield_error .gfield_consent_label {
  color: $error_color;
}

.gform_wrapper .gfield_error textarea,
.gform_wrapper .gfield_error input[type="text"],
.gform_wrapper .gfield_error input[type="url"],
.gform_wrapper .gfield_error input[type="email"],
.gform_wrapper .gfield_error input[type="tel"],
.gform_wrapper .gfield_error input[type="number"],
.gform_wrapper .gfield_error input[type="password"],
.gform_wrapper .gfield_error select {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $error_color;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $error_color;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $error_color;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $error_color;
  }

  &::backdrop {
    /* Firefox 18- */
    color: $error_color;
  }
}
