/* ------------------------------------------------------------ *\
    Article
\* ------------------------------------------------------------ */

.article {
    .article__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include breakpoint-down(mobile) {
            flex-direction: column-reverse;
        }
    }
    .article__content {
        max-width: 45.1%;
        flex: 0 0 45.1%;

        @include breakpoint-down(mobile) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
        
    .article__image {
        margin-top: .9rem;
        max-width: 49.4%;
        flex: 0 0 49.4%;

        @include breakpoint-down(mobile) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 3rem;
        }
        
        
        &.about-us{
	        
	        @include breakpoint-down(mobile) {
	         	
	         	
	        }
	       
        }
    }

    .article__image img {
        display: inline-block;
        width: 100%;
        
        .show-mobile{
	        display: none;
	         @include breakpoint-down(mobile) {
		     	display: block;    
		    }     
	        
        }
    }

    .btn {
        margin-top: 5.5rem;

        @include breakpoint-down(mobile) {
            margin-top: 6.7rem;
        }
    }
    
    
    
}