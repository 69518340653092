/* ------------------------------------------------------------ *\
    Nav 
\* ------------------------------------------------------------ */

.nav {
    font-size: 1.8rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .18rem;
    line-height: 1.2;

    @include breakpoint-down(tablet) {
        font-size: 3.2rem;
        letter-spacing: .12rem;
        line-height: 4.5rem;
        margin-top: 10rem;
        text-transform: none;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    
   

    ul {
        list-style: none;
        padding-left: 0;
    }

    li {
	    position: relative;
        display: inline-block;
        padding: 0 1.75rem;

        @include breakpoint-down(mobile) {
            padding: 1.5rem 1rem;
            display: block;
          
        }
    }
    
    @include breakpoint-down(mobile) {
		li::before {
		    content: '';
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 0;
		    height: 0.1rem;
		    background-color: #999;
		    transition: width 1.2s ease-in-out;
		}
		
		li.animate::before {
			  width: 100%;
			}
    
    }
    
    
    a {
        display: block;
        text-decoration: none;
        transition: opacity $t-duration;

        &:hover {
            text-decoration: none;
            opacity: 0.7;
        }
        
        &.menu-btn{
	        background: #dddddd;
	        border-radius: 5px;
	        padding: 10px 20px;
	        color: #000;
        }
        
    }
}

.menu__Links_mobile{
	
	position: absolute;
	bottom:100px;
	padding-top: 1rem;
	margin: 0rem auto;
	width: 100%;
	display: block;
	
	ul.list-links {
        list-style: none;
        padding-left: 0;
     
        margin: 0rem 1rem;
        padding-top: 1.5rem;
        
    }

    li {
        display: inline-block;
        padding: 0 1.75rem;

        @include breakpoint-down(mobile) {
            padding: 0rem;
            display: block;
        }
        
        p{
	        
	        margin-bottom:0.6rem;
        }
    }
    
    a {
        display: block;
        text-decoration: none;
        transition: opacity $t-duration;
        font-size: 1.4rem;
        line-height: 1.8rem;
        
        @include breakpoint-down(mobile) {
	       line-height: 1.2rem;  
	    }     

        &:hover {
            text-decoration: none;
            opacity: 0.7;
        }
    }
	
}

.header--white {
	.menu-btn:hover{
	        background: #969696;
	         opacity: 1;
        }
	
}

.header--home {
	
	.menu-btn:hover{
	        background: #fff;
	         opacity: 1;
	         color: #000;
        }
	
}

  @include breakpoint-down(mobile) {


	.header.is-active.is-fixed{
		.mobile-menu-btn{
			display: block;
		}
	}
	
	.header--white.is-active.is-fixed{
		.mobile-menu-btn{
			display: block;
		}
	}
	
	.header--home.is-active.is-fixed{
		.mobile-menu-btn{
			display: block;
		}
	}
	
	
	

}
