/* ------------------------------------------------------------ *\
    Wrapper
\* ------------------------------------------------------------ */

.wrapper {
    position: relative;
	min-height: 100svh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}