/* ------------------------------------------------------------ *\
    Cursor
\* ------------------------------------------------------------ */

.cursor {
    position: fixed;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 5;
    top: 0;
    left: 0;
    will-change: transform;
    transition: opacity $t-duration, visibility $t-duration, top $t-duration;
    opacity: 0;
    visibility: hidden;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }

    &.is-bottom {
        top: -6rem;
    }
    
    span {
        display: inline-block;
        background-color: rgba(89, 89, 89, .4);
        backdrop-filter: blur(10px);
        font-size: 1.8rem;
        line-height: 1;
        padding: 2rem 3.1rem;
        border-radius: 4rem;
        letter-spacing: 0.18rem;
    }
}