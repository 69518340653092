/* ------------------------------------------------------------ *\
    Section about
\* ------------------------------------------------------------ */

.section-about {
    padding: 8rem 0;
    background-color: $white;
    color: $black;
    min-height: 93.1rem;

    @include breakpoint-down(large-desktop) {
        min-height: auto;
    }
    
    @include breakpoint-down(mobile) {
        padding: 3.3rem 0 12.1rem;
    }

    .section__head {
        margin-bottom: 2.4rem;

        @include breakpoint-down(mobile) {
            margin-bottom: 2.7rem;
        }
    }
    
    
   
}