/*
----------------------------------------------------------------
formsreset.css
----------------------------------------------------------------
*/

body .gform_wrapper.gf_reset_wrapper,
body .gform_wrapper.gf_reset_wrapper div,
body .gform_wrapper.gf_reset_wrapper span,
body .gform_wrapper.gf_reset_wrapper iframe,
body .gform_wrapper.gf_reset_wrapper h1,
body .gform_wrapper.gf_reset_wrapper h2,
body .gform_wrapper.gf_reset_wrapper h3,
body .gform_wrapper.gf_reset_wrapper h4,
body .gform_wrapper.gf_reset_wrapper h5,
body .gform_wrapper.gf_reset_wrapper h6,
body .gform_wrapper.gf_reset_wrapper p,
body .gform_wrapper.gf_reset_wrapper img,
body .gform_wrapper.gf_reset_wrapper ol,
body .gform_wrapper.gf_reset_wrapper ul,
body .gform_wrapper.gf_reset_wrapper li,
body .gform_wrapper.gf_reset_wrapper fieldset,
body .gform_wrapper.gf_reset_wrapper form,
body .gform_wrapper.gf_reset_wrapper label,
body .gform_wrapper.gf_reset_wrapper legend,
body .gform_wrapper.gf_reset_wrapper input[type="text"],
body .gform_wrapper.gf_reset_wrapper input[type="email"],
body .gform_wrapper.gf_reset_wrapper input[type="tel"],
body .gform_wrapper.gf_reset_wrapper input[type="url"],
body .gform_wrapper.gf_reset_wrapper input[type="number"],
body .gform_wrapper.gf_reset_wrapper input[type="password"],
body .gform_wrapper.gf_reset_wrapper select,
body .gform_wrapper.gf_reset_wrapper textarea,
body .gform_wrapper.gf_reset_wrapper input[type="submit"],
body .gform_wrapper.gf_reset_wrapper input[type="button"],
body .gform_wrapper.gf_reset_wrapper input[type="image"],
body .gform_wrapper.gf_reset_wrapper button,
body .gform_wrapper.gf_reset_wrapper table,
body .gform_wrapper.gf_reset_wrapper caption,
body .gform_wrapper.gf_reset_wrapper tbody,
body .gform_wrapper.gf_reset_wrapper tfoot,
body .gform_wrapper.gf_reset_wrapper thead,
body .gform_wrapper.gf_reset_wrapper tr,
body .gform_wrapper.gf_reset_wrapper th,
body .gform_wrapper.gf_reset_wrapper td {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
}

body .gform_wrapper.gf_reset_wrapper div,
body .gform_wrapper.gf_reset_wrapper span,
body .gform_wrapper.gf_reset_wrapper iframe,
body .gform_wrapper.gf_reset_wrapper p,
body .gform_wrapper.gf_reset_wrapper img,
body .gform_wrapper.gf_reset_wrapper ol,
body .gform_wrapper.gf_reset_wrapper ul,
body .gform_wrapper.gf_reset_wrapper li,
body .gform_wrapper.gf_reset_wrapper fieldset,
body .gform_wrapper.gf_reset_wrapper form,
body .gform_wrapper.gf_reset_wrapper label,
body .gform_wrapper.gf_reset_wrapper legend,
body .gform_wrapper.gf_reset_wrapper input[type="text"],
body .gform_wrapper.gf_reset_wrapper input[type="email"],
body .gform_wrapper.gf_reset_wrapper input[type="tel"],
body .gform_wrapper.gf_reset_wrapper input[type="url"],
body .gform_wrapper.gf_reset_wrapper input[type="number"],
body .gform_wrapper.gf_reset_wrapper input[type="password"],
body .gform_wrapper.gf_reset_wrapper select,
body .gform_wrapper.gf_reset_wrapper textarea,
body .gform_wrapper.gf_reset_wrapper input[type="submit"],
body .gform_wrapper.gf_reset_wrapper input[type="button"],
body .gform_wrapper.gf_reset_wrapper input[type="image"],
body .gform_wrapper.gf_reset_wrapper button,
body .gform_wrapper.gf_reset_wrapper table,
body .gform_wrapper.gf_reset_wrapper caption,
body .gform_wrapper.gf_reset_wrapper tbody,
body .gform_wrapper.gf_reset_wrapper tfoot,
body .gform_wrapper.gf_reset_wrapper thead,
body .gform_wrapper.gf_reset_wrapper tr,
body .gform_wrapper.gf_reset_wrapper th,
body .gform_wrapper.gf_reset_wrapper td {
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  font-weight: normal;
  line-height: 1;
  vertical-align: baseline;
}

body .gform_wrapper.gf_reset_wrapper div,
body .gform_wrapper.gf_reset_wrapper span,
body .gform_wrapper.gf_reset_wrapper p {
  line-height: 1.8
}

body .gform_wrapper.gf_reset_wrapper fieldset,
body .gform_wrapper.gf_reset_wrapper img {
  border: 0
}

body .gform_wrapper.gf_reset_wrapper ol,
body .gform_wrapper.gf_reset_wrapper ul,
body .gform_wrapper.gf_reset_wrapper li {
  list-style: none
}

body .gform_wrapper.gf_reset_wrapper a img {
  border: 0
}

body .gform_wrapper.gf_reset_wrapper :focus {
  outline: 0
}


/*
----------------------------------------------------------------
formsmain.css
----------------------------------------------------------------
*/


/* mobile styles first ​*/

.gform_wrapper {
  margin: 16px 0;
  max-width: 100%;
}

.gform_wrapper form {
  margin: 0 auto;
  max-width: 100%;
  text-align: left;
}

.gform_wrapper *,
.gform_wrapper *:before,
.gform_wrapper *:after {
  box-sizing: border-box !important;
}

.gform_wrapper h1,
.gform_wrapper h2,
.gform_wrapper h3 {
  background: none;
  border: 0;
  font-weight: normal;
}

.gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: normal;
  padding: 5px 4px;
}

.gform_wrapper input[type="image"] {
  border: 0;
  padding: 0 !important;
  width: auto !important;
}

.gform_wrapper textarea {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: normal;
  line-height: 1.5;
  padding: 6px 8px;
  resize: none;
}

.gform_wrapper select {
  line-height: 1.5;
}

.gform_wrapper .ginput_container_multiselect select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: none !important;
  outline: none;
}

.gform_wrapper ul.gform_fields {
  display: block;
  list-style-type: none;
  margin: 0 !important;
}

html>body .entry ul,
.gform_wrapper ul {
  text-indent: 0
}

.gform_wrapper li,
.gform_wrapper form li {
  list-style: none !important;
  list-style-image: none !important;
  list-style-type: none !important;
  margin-left: 0 !important;
  overflow: visible;
}

.gform_wrapper ul li.gfield {
  clear: both;
}

.gform_wrapper ul.gfield_radio li,
.gform_wrapper ul.gfield_checkbox li {
  overflow: hidden;
}

.gform_wrapper ul.gform_fields.right_label li.gfield,
.gform_wrapper form ul.gform_fields.right_label li.gfield,
.gform_wrapper ul.gform_fields.left_label li.gfield,
.gform_wrapper form ul.gform_fields.left_label li.gfield {
  margin-bottom: 14px;
}

.gform_wrapper ul.right_label li ul.gfield_radio li,
.gform_wrapper form ul.right_label li ul.gfield_radio li,
.gform_wrapper ul.left_label li ul.gfield_radio li,
.gform_wrapper form ul.left_label li ul.gfield_radio li,
.gform_wrapper ul.right_label li ul.gfield_checkbox li,
.gform_wrapper form ul.right_label li ul.gfield_checkbox li,
.gform_wrapper ul.left_label li ul.gfield_checkbox li,
.gform_wrapper form ul.left_label li ul.gfield_checkbox li {
  margin-bottom: 10px;
}

.gform_wrapper ul li:before,
.gform_wrapper ul li:after,
.gform_wrapper ul.gform_fields {
  margin: 0;
  overflow: visible;
  padding: 0;
}

.gform_wrapper select {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: normal;
}

.gform_wrapper select option {
  display: block;
  padding: 2px;
}

.gform_wrapper .inline {
  display: inline !important;
}

.gform_wrapper .gform_heading {
  margin-bottom: 18px;
  width: 100%;
}

.gform_wrapper .clear-multi {
  display: flex;
}

/* date ​fields */

.gform_wrapper .gfield_date_day,
.gform_wrapper .gfield_date_year {
  display: -moz-inline-stack;
  display: inline-block;
}

.gform_wrapper .gfield_date_day,
.gform_wrapper .gfield_date_month,
.gform_wrapper .gfield_date_year {
  flex-direction: row;
  width: 33.333%;
}

@media only screen and (min-width: 321px) {

  .gform_wrapper .gfield_date_day,
  .gform_wrapper .gfield_date_month {
    max-width: 4rem;
  }

  .gform_wrapper .gfield_date_year,
  .gform_wrapper .gfield_time_ampm {
    max-width: calc(4rem + .5rem);
  }

}

.gform_wrapper .gfield_date_dropdown_month,
.gform_wrapper .gfield_date_dropdown_day,
.gform_wrapper .gfield_date_dropdown_year {
  flex-direction: row;
  vertical-align: top;
}

.gform_wrapper .gfield_date_dropdown_month,
.gform_wrapper .gfield_date_dropdown_day,
.gform_wrapper .gfield_date_dropdown_year {
  margin-right: .5rem;
}

.gform_wrapper .gfield_date_month,
.gform_wrapper .gfield_date_day {
  margin-right: .5rem;
  width: 4rem;
}

.gform_wrapper .gfield_date_month input[type="number"],
.gform_wrapper .gfield_date_day input[type="number"] {
  width: calc(3rem + 8px) !important;
}

.gform_wrapper .gfield_date_year input[type="number"] {
  width: calc(4rem + 8px) !important;
}

.gform_wrapper .gfield_date_year {
  width: 5rem;
}

.gform_wrapper .gfield_date_month input,
.gform_wrapper .gfield_date_day input {
  width: calc(3rem + 8px);
}

.gform_wrapper .gfield_date_year input {
  width: 4rem;
}

.gform_wrapper .field_sublabel_above div[class*="gfield_date_"].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_date_"].ginput_container label {
  text-align: center;
  width: 3rem;
}

.gform_wrapper .field_sublabel_above div.gfield_date_year.ginput_container label,
.gform_wrapper .field_sublabel_below div.gfield_date_year.ginput_container label {
  width: 4rem;
}

/* time ​fields */

.gform_wrapper .gfield_time_hour,
.gform_wrapper .gfield_time_minute,
.gform_wrapper .gfield_time_ampm {
  flex-direction: row;
  width: 33.333%;
}

@media only screen and (min-width: 321px) {

  .gform_wrapper .gfield_time_hour,
  .gform_wrapper .gfield_time_minute {
    max-width: 4rem;
  }

  .gform_wrapper .gfield_time_hour,
  .gform_wrapper .gfield_time_ampm {
    max-width: calc(4rem + .5rem);
  }

  .gform_wrapper .gfield_time_hour {
    max-width: calc(4rem + .5rem);
  }

}

.gform_wrapper ul.gform_fields:not(.top_label) .ginput_container_time span {
  float: left;
}

.gform_wrapper .gfield_time_hour i {
  float: right;
  font-family: sans-serif !important;
  font-style: normal !important;
  margin-top: 9%;
  text-align: center;
  width: 10px;
}

.gform_wrapper .gfield_time_minute {
  margin-right: .5rem;
}

.gform_wrapper .gfield_time_hour {
  margin-right: .25rem;
}

.gform_wrapper .gfield_time_ampm {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
}

.gform_wrapper .gfield_time_ampm select {
  min-width: calc(2rem + 25px);
  width: calc(3rem + 25px);
}

.gform_wrapper .gfield_time_ampm_shim {
  display: block;
  font-size: .813em;
  margin: 9px 0 1px 1px;
}

.gform_wrapper .gfield_time_hour input,
.gform_wrapper .gfield_time_minute input {
  width: calc(3rem + 8px);
}

.gform_wrapper .gfield_time_hour input[type="number"],
.gform_wrapper .gfield_time_minute input[type="number"] {
  width: calc(3rem + 8px) !important;
}

.gform_wrapper .field_sublabel_above div[class*="gfield_time_"].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_time_"].ginput_container label {
  text-align: center;
  width: 3rem;
}

.gform_wrapper .field_hover {
  border: 1px dashed #2175a9;
  cursor: pointer;
}

.gform_wrapper .field_selected {
  background-color: #dfefff;
  border: 1px solid #c2d7ef;
}

.gform_wrapper .field_name_first,
.gform_wrapper .field_name_middle,
.gform_wrapper .field_name_last {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: top;
  width: 49.5%;
}

.gform_wrapper .ginput_complex.ginput_container {
  overflow: visible;
  width: 100%;
}

.gform_wrapper legend.gfield_label,
.gform_wrapper label.gfield_label {
  font-size: inherit;
  font-weight: 700;
}

.gform_wrapper legend.gfield_label,
.gform_wrapper .top_label .gfield_label {
  clear: both;
  display: -moz-inline-stack;
  display: inline-block;
  line-height: 1.3;
}

.gform_wrapper .top_label .gfield_hide_label {
  display: none;
}

.gform_wrapper .ginput_complex input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='image']):not([type='file']),
.gform_wrapper .ginput_complex .ginput_left input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='image']):not([type='file']),
.gform_wrapper .ginput_complex .ginput_right input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='image']):not([type='file']) {
  width: 100%;
}

.gform_wrapper .ginput_complex .ginput_right select,
.gform_wrapper .ginput_complex select {
  width: 100%;
}

.gform_wrapper .field_sublabel_above .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_below .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_above div[class*="gfield_time_"].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_time_"].ginput_container label,
.gform_wrapper .field_sublabel_above div[class*="gfield_date_"].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_date_"].ginput_container label {
  display: block;
  font-size: .813em;
  letter-spacing: .5pt;
  white-space: nowrap;
}

.gform_wrapper ul.gfield_radio li label,
.gform_wrapper ul.gfield_checkbox li label {
  cursor: pointer;
  display: -moz-inline-stack;
  display: inline-block;
  letter-spacing: inherit;
  vertical-align: middle;
}

.gform_wrapper .field_sublabel_above .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_above div[class*="gfield_time_"].ginput_container label {
  margin: 9px 0 1px 1px;
}

.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_name label,
.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_email label,
.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_password label,
.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_time label {
  margin-top: 0;
}

.gform_wrapper .field_sublabel_below .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_time_"].ginput_container label {
  margin: 1px 0 9px 1px;
}

.gform_wrapper .top_label .field_sublabel_above label.gfield_label+.ginput_complex.ginput_container,
.gform_wrapper .top_label .field_sublabel_above label.gfield_label+div[class*="gfield_time_"].ginput_container {
  margin-top: 0;
}

body .gform_wrapper .top_label div.ginput_container {
  margin-top: 8px;
}

body.gform_wrapper div.clear-multi {
  display: -moz-inline-stack;
  display: inline-block;
}

body .gform_wrapper ul li.field_description_below div.ginput_container_radio,
body .gform_wrapper ul li.field_description_below div.ginput_container_checkbox {
  margin-bottom: 0;
  margin-top: 16px;
}

.gform_wrapper .hidden_label .gfield_label {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

.gfield.right_label,
.gfield.left_label {
  padding-top: 10px;
}

.gform_wrapper li.hidden_label input {
  margin-top: 12px;
}

.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container input[type="text"],
.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container select {
  margin-bottom: 12px;
}

.gform_wrapper .screen-reader-text,
.gform_wrapper label.hidden_sub_label {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.gform_wrapper input.small,
.gform_wrapper select.small,
.gform_wrapper input.medium,
.gform_wrapper select.medium,
.gform_wrapper input.large,
.gform_wrapper select.large,
.gform_wrapper input.large,
.gform_wrapper select.large {
  width: 100%
}

.gform_wrapper input.datepicker_with_icon.small,
.gform_wrapper input.datepicker_with_icon.medium,
.gform_wrapper input.datepicker_with_icon.large {
  width: calc(100% - 24px);
}

.gform_wrapper .gfield_error input.small,
.gform_wrapper .gfield_error select.small,
.gform_wrapper .gfield_error input.medium,
.gform_wrapper .gfield_error select.medium,
.gform_wrapper .gfield_error input.large,
.gform_wrapper .gfield_error select.large,
.gform_wrapper .gfield_error input.large,
.gform_wrapper .gfield_error select.large {
  width: 100%;
}

.gform_wrapper .gfield_error input.datepicker_with_icon.small,
.gform_wrapper .gfield_error input.datepicker_with_icon.medium,
.gform_wrapper .gfield_error input.datepicker_with_icon.large {
  width: calc(100% - 32px);
}

.gform_wrapper textarea.small {
  height: 80px;
  width: 100%;
}

.gform_wrapper textarea.medium {
  height: 120px;
  width: 100%;
}

.gform_wrapper textarea.large {
  height: 160px;
  width: 100%;
}

.gform_wrapper h2.gsection_title {
  letter-spacing: normal !important;
  margin: 0 !important;
  padding: 0 !important;
}

.gform_wrapper .gsection .gfield_label,
.gform_wrapper h2.gsection_title,
.gform_wrapper h3.gform_title {
  font-size: 1.25em;
  font-weight: 700;
}

.gform_wrapper h3.gform_title {
  letter-spacing: normal !important;
  margin: 10px 0 6px 0;
}

.gform_wrapper li.gfield.field_description_below+li.gsection {
  margin-top: 24px !important;
}

.gform_wrapper span.gform_description {
  display: block;
  font-weight: 400;
  margin-bottom: 16px;
  width: calc(100% - 16px);
}

.gform_wrapper .gsection {
  border-bottom: 1px solid #ccc;
  clear: both;
  margin: 28px 0 28px 0;
  padding: 0 16px 8px 0;
}

.gform_wrapper ul.gfield_checkbox li input[type="checkbox"],
.gform_wrapper ul.gfield_radio li input[type="radio"] {
  width: auto !important;
}

.gform_wrapper .gfield_checkbox li,
.gform_wrapper .gfield_radio li {
  position: relative;
}

.gform_wrapper ul.gfield_checkbox,
.gform_wrapper ul.gfield_radio {
  margin: 6px 0;
  padding: 0;
}

.gform_wrapper .gfield_checkbox li label,
.gform_wrapper .gfield_radio li label {
  display: -moz-inline-stack;
  display: inline-block;
  font-size: .875em;
  line-height: 1.5;
  max-width: 85%;
  padding: 0;
  vertical-align: middle;
  white-space: normal;
  width: auto;
}

.gform_wrapper .gfield_checkbox li label {
  margin: 0;
}

.gform_wrapper .gfield_radio li label {
  margin: 2px 0 0 4px;
}

.gform_wrapper .gfield_checkbox li input[type="checkbox"],
.gform_wrapper .gfield_radio li input[type="radio"],
.gform_wrapper .gfield_checkbox li input {
  display: -moz-inline-stack;
  display: inline-block;
  margin-left: 1px;
  vertical-align: middle;
}

.gform_wrapper .description,
.gform_wrapper .gfield_description,
.gform_wrapper .gsection_description,
.gform_wrapper .instruction {
  clear: both;
  font-family: inherit;
  font-size: .813em;
  letter-spacing: normal;
  line-height: inherit;
}

.gform_wrapper .description,
.gform_wrapper .gfield_description,
.gform_wrapper .gsection_description {
  padding: 0 16px 0 0;
}

.gform_wrapper .field_description_below .gfield_description {
  padding-top: 16px;
}

.gform_wrapper .field_sublabel_above .description,
.gform_wrapper .field_sublabel_above .gfield_description,
.gform_wrapper .field_sublabel_above .gsection_description {
  margin-top: 9px;
}

.gform_wrapper .top_label .gsection_description {
  margin-bottom: 9px;
  width: 100%;
}

.gform_wrapper .gfield_description {
  width: 100%;
}

.gform_wrapper .description_above .gfield_description {
  padding: 0 0 10px 0;
}

.gfield_date_year+.gfield_description {
  padding: 0;
}

.gform_wrapper .gfield_consent_description,
.gform_wrapper .field_description_below .gfield_consent_description {
  border: 1px solid #ddd;
  font-size: .688em;
  margin-top: 12px;
  max-height: 240px;
  overflow-y: scroll;
  padding: 6px 8px;
  width: 100%;
}

.gform_wrapper .gfield_required {
  color: #790000;
  margin-left: 4px;
}

// .gform_wrapper li.gfield.gfield_error,
// .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
//   background-color: rgba(255, 223, 224, .25);
//   border-bottom: 1px solid #c89797;
//   border-top: 1px solid #c89797;
//   box-sizing: border-box;
//   margin-bottom: 6px !important;
//   padding-bottom: 6px;
//   padding-top: 8px;
// }

.gform_wrapper li.gfield.gfield_creditcard_warning {
  background-color: rgba(255, 223, 224, 0.25);
  border-bottom: 4px solid #790000;
  border-top: 2px solid #790000;
  padding: 16px 16px 14px 16px;
  width: calc(100% - 16px);
}

.gform_wrapper li.gfield.gfield_error.gfield_creditcard_warning {
  padding: 16px 16px 14px 16px;
}

.gform_wrapper li.gfield.gfield_creditcard_warning div.gfield_creditcard_warning_message {
  color: #790000;
  font-family: inherit;
  font-size: .875em;
  font-weight: 700;
  max-width: 100%;
  min-height: 25px;
  position: relative;
}

.gform_wrapper li.gfield.gfield_creditcard_warning div.gfield_creditcard_warning_message span {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSItMTA2NyAyODY1IDI0IDMyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IC0xMDY3IDI4NjUgMjQgMzI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiM3OTAwMDA7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0tMTA1MywyODY1Yy01LjUsMC0xMCw0LjUtMTAsMTBoNGMwLTMuMywyLjctNiw2LTZjMy4zLDAsNiwyLjcsNiw2djJoLTIwdjE0YzAsMy4zLDIuNyw2LDYsNmgxMg0KCWMzLjMsMCw2LTIuNyw2LTZ2LTE2Qy0xMDQzLDI4NjkuNS0xMDQ3LjUsMjg2NS0xMDUzLDI4NjV6IE0tMTA0OSwyODkzaC0xMmMtMS4xLDAtMi0wLjktMi0ydi0xMGgxNnYxMA0KCUMtMTA0NywyODkyLjEtMTA0Ny45LDI4OTMtMTA0OSwyODkzeiBNLTEwNTMsMjg4N2MwLDEuMS0wLjksMi0yLDJzLTItMC45LTItMmMwLTEuMSwwLjktMiwyLTJTLTEwNTMsMjg4NS45LTEwNTMsMjg4N3oiLz4NCjwvc3ZnPg0K');
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 1em auto;
  border-bottom: 1px solid #790000;
  display: block;
  letter-spacing: .1pt;
  line-height: 1.5;
  margin-bottom: 16px;
  padding: 0 0 14px 24px;
  text-transform: uppercase;
}

.gform_wrapper li.gfield.gfield_error.gfield_creditcard_warning div.gfield_creditcard_warning_message {
  padding: 16px 16px 14px 16px;
}

li.gfield+li.gfield.gfield_creditcard_warning {
  margin-top: 16px !important;
}

.gform_wrapper .top_label .gfield_error {
  width: calc(100% - 2px);
}

.gform_wrapper .top_label .gfield_error input.large,
.gform_wrapper .top_label .gfield_error select.large,
.gform_wrapper .top_label .gfield_error textarea.textarea {
  width: 100%;
}

.gform_wrapper .right_label .gfield_error input.large,
.gform_wrapper .right_label .gfield_error select.large,
.gform_wrapper .right_label .gfield_error textarea.textarea,
.gform_wrapper .left_label .gfield_error input.large,
.gform_wrapper .left_label .gfield_error select.large,
.gform_wrapper .left_label .gfield_error textarea.textarea {
  width: 70%;
}

.gform_wrapper .gfield_error .gfield_label {
  color: #790000;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required {
  padding-right: 0;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_time_minute.ginput_container,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_time_ampm.ginput_container {
  margin-left: 0;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_description {
  max-width: 100%;
  padding-right: 16px;
}

// .gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label,
// .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container {
//   margin-top: 12px
// }

.gform_wrapper li.gfield.gfield_error.gfield_contains_required fieldset legend.gfield_label {
  position: relative;
  top: 12px;
}

.gform_wrapper div.validation_error {
  border-bottom: 2px solid #790000;
  border-top: 2px solid #790000;
  clear: both;
  color: #790000;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 25px;
  padding: 16px 0 16px 0;
  text-align: center;
  width: 100%;
}

.gform_wrapper ol.validation_list {
  clear: both;
  color: #790000;
  margin-bottom: 25px;
  width: 100%;
}

.gform_wrapper ol.validation_list li {
  list-style-type: decimal !important;
}

.gform_wrapper ol.validation_list li a {
  color: #790000;
}

.gform_wrapper ol.validation_list:after {
  content: "";
}

.gform_wrapper div#error {
  margin-bottom: 25px;
}

.gform_wrapper table.gfield_list tbody tr td.gfield_list_icons {
  vertical-align: middle !important;
}

.gform_wrapper li.gfield fieldset {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.gform_wrapper li.gfield fieldset>* {
  width: auto
}

.gform_wrapper li.gfield fieldset legend.gfield_label {
  line-height: 32px;
  width: 100%;
}

div.gf_page_steps+div.validation_error {
  margin-top: 16px;
}

.gform_wrapper div.gfield_description.validation_error {
  color: #790000;
  font-size: .875em;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 16px;
}

.gform_wrapper .validation_message {
  color: #790000;
  font-weight: 700;
  letter-spacing: normal;
}

.gform_wrapper li.gfield_error input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
.gform_wrapper li.gfield_error textarea {
  border: 1px solid #790000;
}

.gform_wrapper li.gfield_error div.ginput_complex.ginput_container label,
.gform_wrapper li.gfield_error ul.gfield_checkbox,
.gform_wrapper li.gfield_error ul.gfield_radio {
  color: #790000;
}

.gform_wrapper .gform_footer {
  clear: both;
  margin: 16px 0 0 0;
  padding: 16px 0 10px 0;
  width: 100%;
}

.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_page_footer input.button,
.gform_wrapper .gform_footer input[type="submit"],
.gform_wrapper .gform_page_footer input[type="submit"] {
  font-size: 1em;
  margin: 0 0 16px 0;
  width: 100%;
}

.gform_wrapper .gform_footer a.gform_save_link,
.gform_wrapper .gform_page_footer a.gform_save_link {
  display: block;
  font-size: 1em;
  text-align: center;
}

.gform_wrapper .gform_footer input[type="image"] {
  background: none !important;
  border: 0;
  display: block;
  margin: 0 auto 16px auto !important;
  max-width: 100%;
  padding: 0;
  width: auto !important;
}

.gform_wrapper .ginput_complex .ginput_left,
.gform_wrapper .ginput_complex .ginput_right,
.gform_wrapper .ginput_complex .ginput_full {
  min-height: 45px;
}

.gform_wrapper .ginput_complex .ginput_full input[type="text"],
.gform_wrapper .ginput_complex .ginput_full input[type="url"],
.gform_wrapper .ginput_complex .ginput_full input[type="email"],
.gform_wrapper .ginput_complex .ginput_full input[type="tel"],
.gform_wrapper .ginput_complex .ginput_full input[type="number"],
.gform_wrapper .ginput_complex .ginput_full input[type="password"] {
  width: 100%;
}

.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type="text"],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type="url"],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type="email"],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type="tel"],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type="number"],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type="password"],
.gform_wrapper .gfield_error .ginput_complex .ginput_full select {
  width: 100%;
}

.gform_wrapper .gfield_checkbox li,
.gform_wrapper .gfield_radio li {
  line-height: 1.2;
  margin: 0 0 16px 0;
}

.gform_wrapper ul.gfield_radio li input[type="radio"]:checked+label,
.gform_wrapper ul.gfield_checkbox li input[type="checkbox"]:checked+label,
.gchoice_select_all {
  font-weight: 700;
}

.gform_wrapper input.datepicker.datepicker_with_icon {
  display: -moz-inline-stack;
  display: inline-block;
  margin-right: 4px !important;
}

.gform_wrapper input[type="hidden"],
.gform_wrapper input.gform_hidden,
.gform_wrapper .gform_hidden,
.gform_wrapper .gf_hidden {
  display: none !important;
  max-height: 1px !important;
  overflow: hidden;
}

.gform_wrapper .gfield_visibility_hidden,
.gform_wrapper .gf_invisible {
  left: -9999px;
  position: absolute;
  visibility: hidden;
}

.gform_wrapper .ginput_full br,
.gform_wrapper .ginput_left br,
.gform_wrapper .ginput_right br {
  display: none !important;
}

.gform_wrapper ul.gfield_checkbox li,
.gform_wrapper ul.gfield_radio li {
  padding: 0 !important;
}

.gform_wrapper ul.gfield_radio li input+input {
  margin-left: 4px;
}

.gform_wrapper ul.gfield_radio li input[value="gf_other_choice"] {
  margin-right: 6px;
  margin-top: 4px;
}

.gform_wrapper .gfield_description+.gform_wrapper .gfield_description.validation_message {
  margin-top: 6px;
}

.gform_wrapper .ginput_container.ginput_list+.gfield_description.validation_message {
  margin-top: 0;
}

.gform_wrapper li.gfield_html {
  max-width: 100%;
}

.gform_wrapper .gfield_html.gfield_no_follows_desc {
  margin-top: 10px;
}

body .gform_wrapper ul li.gfield.gfield_html img {
  max-width: 100% !important;
}

.gform_wrapper .gform_ajax_spinner {
  padding-left: 10px;
}

.gform_validation_container,
.gform_wrapper .gform_validation_container,
body .gform_wrapper li.gform_validation_container,
body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container,
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
  display: none !important;
  left: -9000px;
  position: absolute !important;
}

.gform_wrapper .gfield_captcha_input_container {
  padding-top: 3px;
}

.gform_wrapper .simple_captcha_small input {
  width: 64px;
}

.gform_wrapper .simple_captcha_medium input {
  width: 96px;
}

.gform_wrapper .simple_captcha_large input {
  width: 128px;
}

.gform_wrapper .gform_wrapper .left_label .simple_captcha_small,
.gform_wrapper .right_label .simple_captcha_small,
.gform_wrapper .left_label .simple_captcha_medium,
.gform_wrapper .right_label .simple_captcha_medium,
.gform_wrapper .left_label .simple_captcha_large,
.gform_wrapper .right_label .simple_captcha_large {
  margin-left: 32%;
}

.gform_wrapper .gfield_captcha_container img.gfield_captcha {
  background: none !important;
  border: 0;
  float: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.gform_wrapper .math_small input {
  width: 70px;
}

.gform_wrapper .math_medium input {
  width: 510px;
}

.gform_wrapper .math_large input {
  width: 612px;
}

.gform_wrapper .left_label .math_small,
.gform_wrapper .right_label .math_small,
.gform_wrapper .left_label .math_medium,
.gform_wrapper .right_label .math_medium,
.gform_wrapper .left_label .math_large,
.gform_wrapper .right_label .math_large {
  margin-left: 32%;
}


.gform_wrapper div.charleft {
  color: #767676;
  font-size: .688em;
  margin-top: 4px;
  white-space: nowrap !important;
  width: 92% !important;
}

.gform_wrapper div.charleft[style] {
  width: 92% !important;
}

.gform_wrapper .left_label div.charleft,
.gform_wrapper .right_label div.charleft {
  margin-left: 32%;
}

.gform_wrapper div.charleft.ginput_counter_tinymce {
  border: 1px solid #eee;
  border-width: 1px 0 0;
  padding: 4px 9px;
  width: 100% !important;
}

.gform_wrapper div.charleft.warningTextareaInfo {
  color: #767676;
}

.gform_wrapper div.charleft.ginput_counter_error {
  color: #f00;
}

.gform_wrapper li.gf_hide_charleft div.charleft {
  display: none !important;
}

.gf_submission_limit_message {
  color: #790000;
  font-size: 1.375em;
}

.gform_wrapper .ginput_price {
  -khtml-opacity: .7;
  -moz-opacity: .7;
  filter: alpha(opacity=70);
  opacity: .7;
}

.gform_wrapper span.ginput_total {
  color: #060;
  font-size: inherit;
}

.gform_wrapper .top_label span.ginput_total {
  margin: 8px 0;
}

.gform_wrapper span.ginput_product_price_label {
  margin-right: 2px;
}

.gform_wrapper span.ginput_product_price {
  color: #900;
}

.gform_wrapper span.ginput_quantity_label {
  margin-left: 10px;
  margin-right: 2px;
}

.gform_wrapper input.ginput_quantity[type="text"] {
  width: 3rem;
}

.gform_wrapper input.ginput_quantity[type="number"] {
  width: 4rem;
}

.gform_wrapper .gform_page_footer {
  border-top: 1px dotted #ccc;
  clear: both;
  margin: 14px 0;
  padding: 16px 0 0 0;
  width: 100%;
}

.gform_wrapper .gform_page_footer.left_label,
.gform_wrapper .gform_page_footer.right_label {
  padding: 16px 0 0 29%;
}

.gform_wrapper .gform_page_footer .button.gform_previous_button,
.gform_wrapper .gform_page_footer .button.gform_next_button {
  display: -moz-inline-stack;
  display: inline-block;
}

.gform_wrapper .gform_page_footer .button.gform_previous_button {
  margin-bottom: 8px;
}

.gform_wrapper .gf_progressbar_wrapper {
  clear: both;
  margin: 0 0 16px 0;
  padding: 0 0 16px 0;
  width: 100%;
}

.gform_wrapper .gf_progressbar_wrapper h3.gf_progressbar_title {
  -khtml-opacity: .6;
  -moz-opacity: .6;
  clear: both;
  filter: alpha(opacity=60);
  font-size: .813em;
  line-height: 1 !important;
  margin: 0 0 8px 12px !important;
  opacity: .6;
  padding: 0 !important;
}

.gform_wrapper .gf_progressbar {
  -moz-border-radius: 25px;
  -moz-box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 25px;
  -webkit-box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 10px;
  position: relative;
  width: 100%;
}

.gform_wrapper .gf_progressbar_percentage {
  background: repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px), linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 20px;
  border-top-right-radius: 4px;
  font-family: helvetica, arial, sans-serif;
  font-size: .813em !important;
  height: 24px;
  text-align: right;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  vertical-align: middle;
  z-index: 999;
}

.gform_wrapper .gf_progressbar:after {
  -moz-box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.05);
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.05);
  content: "";
  display: block;
  height: 24px;
  margin-top: -24px;
  width: 100%;
  z-index: 990;
}

.gform_wrapper .gf_progressbar_percentage.percentbar_100 {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.gform_wrapper .gf_progressbar_percentage span {
  display: block;
  float: right;
  line-height: 1.8;
  margin-left: 5px;
  margin-right: 5px;
  width: auto;
}

.gform_wrapper .gf_progressbar_percentage.percentbar_0 span {
  color: #959595;
  float: none !important;
  margin-left: 12px;
  text-shadow: none;
  word-wrap: normal;
}

.gform_wrapper .percentbar_blue {
  background-color: #036493;
  color: #fff;
}

.gform_wrapper .percentbar_gray {
  background-color: #7c7c7c;
  color: #fff;
}

.gform_wrapper .percentbar_green {
  background-color: #88b831;
  color: #fff;
}

.gform_wrapper .percentbar_orange {
  background-color: #ff7120;
  color: #fff;
}

.gform_wrapper .percentbar_red {
  background-color: #ff2a1a;
  color: #fff;
}

.gform_wrapper .gf_page_steps {
  border-bottom: 1px dotted #ccc;
  margin: 0 0 8px 0;
  padding: 0 0 4px 0;
  width: 100%;
}

.gform_wrapper .gf_step {
  -khtml-opacity: .2;
  -moz-opacity: .2;
  filter: alpha(opacity=20);
  font-family: inherit;
  font-size: .875em;
  height: 14px;
  line-height: 1.25 !important;
  margin: 24px 24px 24px 0;
  opacity: .2;
  width: 100%;
}

.gform_wrapper .gf_step span.gf_step_number {
  font-family: arial, sans-serif;
  font-size: 1.25em;
  margin-right: 4px;
}

.gform_wrapper .gf_step span.gf_step_number,
.gform_wrapper .gf_step span.gf_step_label {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
}

.gform_wrapper .gf_step.gf_step_active {
  -khtml-opacity: 1;
  -moz-opacity: 1;
  filter: alpha(opacity=100);
  opacity: 1;
}

.gform_wrapper .gf_step_clear {
  clear: both;
  display: block;
  height: 1px;
  overflow: hidden;
}

.gform_wrapper .gfield_password_strength {
  background-color: #eee;
  border: 1px solid #ddd;
  border-bottom: 3px solid #ddd;
  line-height: 1.8;
  margin-bottom: 18px;
  margin-top: 18px;
  padding: 10px 5px;
  text-align: center;
}

.gform_wrapper ul.top_label li.gfield.gfield_error.gfield_contains_required .gfield_password_strength {
  margin-right: 16px;
}

.gform_wrapper ul.left_label li.gfield .gfield_password_strength,
.gform_wrapper ul.right_label li.gfield .gfield_password_strength {
  margin-bottom: 18px;
  margin-top: 18px;
  width: 100%;
}

.gform_wrapper .gfield_password_strength.bad {
  background-color: #ffb78c;
  border-bottom: 3px solid #ff853c;
  border-color: #ff853c;
}

.gform_wrapper .gfield_password_strength.good {
  background-color: #ffec8b;
  border-bottom: 3px solid #fc0;
  border-color: #fc0;
}

.gform_wrapper .gfield_password_strength.short,
.gform_wrapper .gfield_password_strength.mismatch {
  background-color: #ffa0a0;
  border-bottom: 3px solid #f04040;
  border-color: #f04040;
}

.gform_wrapper .gfield_password_strength.strong {
  background-color: #c3ff88;
  border-bottom: 3px solid #8dff1c;
  border-color: #8dff1c;
}

.ginput_container_password,
.ginput_container_password span {
  position: relative;
}

.ginput_container_password button {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  padding: 5px 0;
  position: absolute;
  right: 1px;
  text-align: center;
  top: 1px;
  width: 40px;
}

.ginput_container_password span.ginput_left button {
  right: 9px;
}

.ginput_container_password:not(.ginput_complex) span {
  display: inline-block;
  width: 100%;
}

.ginput_container_password:not(.ginput_complex) span input {
  width: 100%;
}

.ginput_container_password span.medium {
  width: calc(50% - 8px);
}

.ginput_container_password span.small {
  width: 25%;
}

.gform_wrapper table.gfield_list,
.gform_wrapper table.gfield_list caption,
.gform_wrapper table.gfield_list tbody,
.gform_wrapper table.gfield_list tfoot,
.gform_wrapper table.gfield_list thead,
.gform_wrapper table.gfield_list tr,
.gform_wrapper table.gfield_list th,
.gform_wrapper table.gfield_list td {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

.gform_wrapper table.gfield_list {
  border-collapse: collapse;
  border-spacing: 0;
}

.gfield_repeater_buttons {
  padding-top: 15px;
}

/* fix for twentyfifteen theme table layout issue */

.gform_wrapper table.gfield_list {
  table-layout: auto !important;
}

.gform_wrapper table.gfield_list thead th {
  font-weight: 700;
  text-align: left;
}

.gform_wrapper table.gfield_list colgroup {
  display: block;
  width: 100%;
}

.gform_wrapper li.gfield.gfield_error table.gfield_list thead th {
  color: #790000;
}

.gform_wrapper table.gfield_list thead,
.gform_wrapper table.gfield_list tr {
  margin: 0;
  padding: 0;
}

.gform_wrapper table.gfield_list th,
.gform_wrapper table.gfield_list td {
  padding: 4px 0 4px 0;
}

.gform_wrapper ul.gform_fields:not(.top_label) table.gfield_list th {
  padding: 0 0 4px 0;
}

.gform_wrapper table.gfield_list th+th,
.gform_wrapper table.gfield_list td+td {
  padding: 0 0 0 16px;
}

.gform_wrapper .gfield_list {
  width: 100%;
}

.gform_wrapper .gfield_list td.gfield_list_cell input {
  width: 100%;
}

.gfield_icon_disabled {
  -khtml-opacity: .6;
  -moz-opacity: .6;
  cursor: default !important;
  filter: alpha(opacity=60);
  opacity: .6;
}

.gform_wrapper table.gfield_list tr td.gfield_list_icons {
  box-sizing: border-box;
  padding: 0 0 0 4px !important;
  width: 48px !important;
}

.gform_wrapper table.gfield_list td.gfield_list_icons img {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
}


.gform_wrapper .gform_card_icon_container {
  height: 32px;
  margin: 8px 0 6px 0;
}

.gform_wrapper div.gform_card_icon {
  background-image: url('../images/gf-creditcard-icons.png');
  background-repeat: no-repeat;
  float: left;
  height: 32px;
  margin-right: 4px;
  text-indent: -9000px;
  width: 36px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_visa {
  background-position: 0 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_visa {
  background-position: 0 -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_visa {
  background-position: 0 -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_mastercard {
  background-position: -36px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_mastercard {
  background-position: -36px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_mastercard {
  background-position: -36px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_amex {
  background-position: -72px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_amex {
  background-position: -72px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_amex {
  background-position: -72px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_discover {
  background-position: -108px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_discover {
  background-position: -108px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_discover {
  background-position: -108px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_maestro {
  background-position: -144px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_maestro {
  background-position: -144px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_maestro {
  background-position: -144px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_jcb {
  background-position: -180px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_jcb {
  background-position: -180px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_jcb {
  background-position: -180px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_visa {
  background-position: 0 -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_visa {
  background-position: 0 -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_visa {
  background-position: 0 -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_mastercard {
  background-position: -36px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_mastercard {
  background-position: -36px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_mastercard {
  background-position: -36px -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_amex {
  background-position: -72px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_amex {
  background-position: -72px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_amex {
  background-position: -72px -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_discover {
  background-position: -108px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_discover {
  background-position: -108px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_discover {
  background-position: -108px -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_maestro {
  background-position: -144px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_maestro {
  background-position: -144px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_maestro {
  background-position: -144px -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_jcb {
  background-position: -180px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_jcb {
  background-position: -180px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_jcb {
  background-position: -180px -256px;
}

.gform_card_icon_container input[type="radio"]#gform_payment_method_creditcard {
  float: left;
  position: relative;
  top: 4px !important;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right {
  min-width: 85px !important;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left label,
.gform_wrapper .ginput_complex .ginput_cardinfo_right label {
  white-space: nowrap !important;
}

.gform_wrapper .ginput_complex span.ginput_cardextras {
  display: block;
  overflow: hidden;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left span.ginput_card_expiration_container {
  display: block;
  min-width: 160px !important;
  position: relative;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left span.ginput_card_expiration_container select {
  margin-bottom: 8px;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right input.ginput_card_security_code {
  max-width: 50% !important
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right span.ginput_card_security_code_icon {
  background-image: url('../images/gf-creditcard-icons.png');
  background-position: 0 -128px;
  background-repeat: no-repeat;
  display: -moz-inline-stack;
  display: inline-block;
  height: 23px;
  left: 6px;
  position: relative;
  top: -1px;
  width: 32px;
}

.gform_wrapper .gform_fileupload_multifile .gform_drop_area {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  background: rgba(0, 0, 0, 0.02);
  border: 1px dashed #ccc;
  border-radius: 4px;
  color: #aaa;
  margin-bottom: 16px;
  padding: 24px;
  text-align: center;
}

.gform_wrapper span.gform_drop_instructions {
  display: block;
  margin-bottom: 8px;
}

.gform_delete {
  cursor: pointer;
  vertical-align: middle;
}

.gform_wrapper .copy_values_option_container .copy_values_enabled {
  width: auto;
}

.gform_wrapper li.field_sublabel_above .ginput_complex input[type="text"] {
  margin-bottom: 1px;
}

/* save and continue dialog and form */

.gform_wrapper .form_saved_message {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background-color: rgba(227, 243, 243, 1);
  border-radius: 2px;
  color: rgba(49, 112, 143, 1);
  font-size: 1em;
  line-height: 1.8;
  margin: 1em auto;
  padding: 3em 2em 3em;
  text-align: center;
  width: 99%;
}

.gform_wrapper .form_saved_message form {
  text-align: center;
}

.form_saved_message p.resume_form_link_wrapper {
  -moz-border-radius: 2px;
  -moz-box-shadow: 0 3px 2px 0 rgba(66, 103, 126, .2);
  -webkit-border-radius: 2px;
  -webkit-box-shadow: 0 3px 2px 0 rgba(66, 103, 126, .2);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 2px;
  box-shadow: 0 3px 2px 0 rgba(66, 103, 126, .2);
  display: inline-block;
  max-width: 98% !important;
  overflow: hidden;
  padding: 1em;
}

.form_saved_message a.resume_form_link {
  color: rgba(47, 99, 126, 1);
  text-decoration: none;
  word-wrap: break-word;
}

.form_saved_message .form_saved_message_emailform {
  margin: 1em 0 0 0;
}

.form_saved_message label.form_saved_label {
  left: -9000px;
  position: absolute;
}

div.form_saved_message div.form_saved_message_emailform form input[type="text"] {
  margin-bottom: .25em;
}

div.form_saved_message div.form_saved_message_emailform form input {
  display: inline-block;
  margin: .75em auto 0 auto;
}

div.form_saved_message div.form_saved_message_emailform form input[type="email"] {
  -moz-appearance: none;
  -moz-border-radius: 2px;
  -moz-box-shadow: inset 0 0 5px 0 rgba(238, 238, 238, 1);
  -webkit-appearance: none;
  -webkit-border-radius: 2px;
  -webkit-box-shadow: inset 0 0 5px 0 rgba(238, 238, 238, 1);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(145, 175, 199, 1);
  border-radius: 2px;
  box-shadow: inset 0 0 5px 0 rgba(238, 238, 238, 1);
  color: rgba(66, 66, 66, 1);
  font-size: 1em;
  max-width: 98% !important;
  padding: calc(.75em - 1px) !important;
  width: 24em;
}

div.form_saved_message div.form_saved_message_emailform form input[type="submit"] {
  -moz-appearance: none;
  -moz-border-radius: 2px;
  -webkit-appearance: none;
  -webkit-border-radius: 2px;
  background-color: rgba(145, 175, 199, 1);
  border: 1px solid rgba(145, 175, 199, 1);
  border-radius: 2px;
  color: rgba(255, 255, 25566, 1);
  font-size: 1em;
  line-height: 1;
  padding: .75em 1em;
  text-transform: uppercase;
}

@media only screen and (max-width: 640px) {

  div.form_saved_message div.form_saved_message_emailform form input[type="email"],
  div.form_saved_message div.form_saved_message_emailform form input[type="submit"] {
    display: block;
    width: 98%;
  }
}

.form_saved_message .form_saved_message_emailform .validation_message {
  color: #790000;
  font-weight: 700;
  padding: 2em;
}

.form_saved_message_sent {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background-color: rgba(233, 255, 217, 1);
  border-radius: 2px;
  box-sizing: border-box;
  color: rgba(60, 118, 61, 1);
  font-size: 1em;
  margin: 1em auto;
  padding: 3em 2em;
  text-align: center;
  width: 99%;
}

.form_saved_message_sent .saved_message_email,
.form_saved_message_sent .saved_message_success {
  display: block;
  font-weight: 700;
  line-height: 2;
}

.form_saved_message_sent .saved_message_email {
  text-decoration: underline;
  word-wrap: break-word;
}

body #content .entry-content .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container #recaptcha_widget_div #recaptcha_area .recaptchatable tbody tr td .recaptcha_input_area,
body .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container #recaptcha_widget_div #recaptcha_area .recaptchatable tbody tr td .recaptcha_input_area {
  line-height: 1 !important;
}

body .gform_wrapper img.ui-datepicker-trigger {
  display: -moz-inline-stack;
  display: inline-block;
  height: 16px !important;
  width: 17px !important;
}

body .gform_wrapper img.add_list_item,
body .gform_wrapper img.delete_list_item {
  display: -moz-inline-stack;
  display: inline-block;
  margin-top: -2px;
}

.gform_wrapper ul li.gfield.field_sublabel_above.field_description_below+li.gfield {
  margin-top: 16px;
}

.gform_wrapper li.gfield ul.gfield_radio li input[type="radio"]+input[type="text"] {
  width: 45.25%;
}

.gform_wrapper table.recaptchatable {
  table-layout: auto;
}

/* adjusting the captcha margin for left or right label settings */

.gform_wrapper ul.gform_fields:not(.top_label) div.ginput_container_captcha {
  margin-left: 29%;
}

/* rich text editor */

.gfield .wp-editor-container {
  border: 1px solid #ddd;
}

.gfield .wp-switch-editor {
  border-color: #ddd;
}

.gfield .tmce-active .switch-tmce,
.gfield .html-active .switch-html {
  border-bottom-color: #f5f5f5;
}

.gfield .mce-edit-area iframe {
  margin: 0 !important;
  width: 99.9% !important;
}

body .gform_wrapper ul li.gfield {
  margin-top: 16px;
  padding-top: 0;
}

body .gform_wrapper ul.gfields li.gfield label.gfield_label {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

/* do our best to restore styles for lists inside the HTML field */

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html {
  line-height: inherit;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ul li,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ul li {
  list-style-type: disc !important;
  margin: 0 0 8px 0;
  overflow: visible;
  padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ul,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ul {
  list-style-type: disc !important;
  margin: 16px 0 16px 28px !important;
  padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol li,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol li {
  list-style-type: decimal !important;
  margin: 0 0 8px 0;
  overflow: visible;
  padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol {
  list-style-type: decimal !important;
  margin: 16px 0 18px 32px;
  padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul li {
  list-style-type: disc !important;
  padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul {
  margin: 16px 0 16px 16px;
}

body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul li {
  margin: 0 0 8px;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html dl {
  margin: 0 0 18px 0;
  padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html dl dt {
  font-weight: 700;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html dl dd {
  margin: 0 0 16px 18px;
}

.gform_wrapper span.gf_clear_span {
  clear: both;
  display: block;
  float: none;
  height: 1px;
  margin-top: -1px;
  overflow: hidden;
  width: 100%;
}

/* updated chosen 1.8.5 styles - defaults  */

/* @group Base */
.gform_wrapper .chosen-container {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  display: inline-block;
  font-size: 13px;
  position: relative;
  user-select: none;
  vertical-align: middle;
}

.gform_wrapper .chosen-container * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.gform_wrapper .chosen-container .chosen-drop {
  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  background: #fff;
  border: 1px solid #aaa;
  border-top: 0;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1010;
}

.gform_wrapper .chosen-container.chosen-with-drop .chosen-drop {
  display: block;
}

.gform_wrapper .chosen-container a {
  cursor: pointer;
}

.gform_wrapper .chosen-container .search-choice .group-name,
.gform_wrapper .chosen-container .chosen-single .group-name {
  color: #999;
  font-weight: normal;
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gform_wrapper .chosen-container .search-choice .group-name:after,
.gform_wrapper .chosen-container .chosen-single .group-name:after {
  content: ":";
  padding-left: 2px;
  vertical-align: top;
}

/* @end */
/* @group Single Chosen */
.gform_wrapper .chosen-container-single .chosen-single {
  -webkit-box-shadow: 0 0 3px #fff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #fff), color-stop(50%, #f6f6f6), color-stop(52%, #eee), to(#f4f4f4));
  background: linear-gradient(#fff 20%, #f6f6f6 50%, #eee 52%, #f4f4f4 100%);
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #fff inset, 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #444;
  display: block;
  height: 25px;
  line-height: 24px;
  overflow: hidden;
  padding: 0 0 0 8px;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}

.gform_wrapper .chosen-container-single .chosen-single input[type="text"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: 0;
}

.gform_wrapper .chosen-container-single .chosen-default {
  color: #999;
}

.gform_wrapper .chosen-container-single .chosen-single span {
  display: block;
  margin-right: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.gform_wrapper .chosen-container-single .chosen-single-with-deselect span {
  margin-right: 38px;
}

.gform_wrapper .chosen-container-single .chosen-single abbr {
  background: url("chosen-sprite.png") -42px 1px no-repeat;
  display: block;
  font-size: 1px;
  height: 12px;
  position: absolute;
  right: 26px;
  top: 6px;
  width: 12px;
}

.gform_wrapper .chosen-container-single .chosen-single abbr:hover {
  background-position: -42px -10px;
}

.gform_wrapper .chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  background-position: -42px -10px;
}

.gform_wrapper .chosen-container-single .chosen-single div {
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
}

.gform_wrapper .chosen-container-single .chosen-single div b {
  background: url("chosen-sprite.png") no-repeat 0 2px;
  display: block;
  height: 100%;
  width: 100%;
}

.gform_wrapper .chosen-container-single .chosen-search {
  margin: 0;
  padding: 3px 4px;
  position: relative;
  white-space: nowrap;
  z-index: 1010;
}

.gform_wrapper .chosen-container-single .chosen-search input[type="text"] {
  background: url("chosen-sprite.png") no-repeat 100% -20px;
  border: 1px solid #aaa;
  border-radius: 0;
  font-family: sans-serif;
  font-size: 1em;
  height: auto;
  line-height: normal;
  margin: 1px 0;
  outline: 0;
  padding: 4px 20px 4px 5px;
  width: 100%;
}

.gform_wrapper .chosen-container-single .chosen-drop {
  background-clip: padding-box;
  border-radius: 0 0 4px 4px;
  margin-top: -1px;
}

.gform_wrapper .chosen-container-single.chosen-container-single-nosearch .chosen-search {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

/* @end */
/* @group Results */
.gform_wrapper .chosen-container .chosen-results {
  -webkit-overflow-scrolling: touch;
  color: #444;
  margin: 0 4px 4px 0;
  max-height: 240px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 0 0 4px;
  position: relative;
}

.gform_wrapper .chosen-container .chosen-results li {
  -webkit-touch-callout: none;
  display: none;
  line-height: 15px;
  list-style: none;
  margin: 0;
  padding: 5px 6px;
  word-wrap: break-word;
}

.gform_wrapper .chosen-container .chosen-results li.active-result {
  cursor: pointer;
  display: list-item;
}

.gform_wrapper .chosen-container .chosen-results li.disabled-result {
  color: #ccc;
  cursor: default;
  display: list-item;
}

.gform_wrapper .chosen-container .chosen-results li.highlighted {
  background-color: #3875d7;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #3875d7), color-stop(90%, #2a62bc));
  background-image: linear-gradient(#3875d7 20%, #2a62bc 90%);
  color: #fff;
}

.gform_wrapper .chosen-container .chosen-results li.no-results {
  background: #f4f4f4;
  color: #777;
  display: list-item;
}

.gform_wrapper .chosen-container .chosen-results li.group-result {
  cursor: default;
  display: list-item;
  font-weight: bold;
}

.gform_wrapper .chosen-container .chosen-results li.group-option {
  padding-left: 15px;
}

.gform_wrapper .chosen-container .chosen-results li em {
  font-style: normal;
  text-decoration: underline;
}

/* @end */
/* @group Multi Chosen */
.gform_wrapper .chosen-container-multi .chosen-choices {
  background-color: #fff;
  background-image: linear-gradient(#eee 1%, #fff 15%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #eee), color-stop(15%, #fff));
  border: 1px solid #aaa;
  cursor: text;
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0 5px;
  position: relative;
  width: 100%;
}

.gform_wrapper .chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  -webkit-box-shadow: none;
  background: transparent !important;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: #999;
  font-family: sans-serif;
  font-size: 100%;
  height: 25px;
  line-height: normal;
  margin: 1px 0;
  outline: 0;
  padding: 0;
  width: 25px;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice {
  -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  background-clip: padding-box;
  background-color: #eee;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  background-repeat: repeat-x;
  background-size: 100% 19px;
  border: 1px solid #aaa;
  border-radius: 3px;
  box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  color: #333;
  cursor: default;
  line-height: 13px;
  margin: 3px 5px 3px 0;
  max-width: 100%;
  padding: 3px 20px 3px 5px;
  position: relative;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice span {
  word-wrap: break-word;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  background: url("chosen-sprite.png") -42px 1px no-repeat;
  display: block;
  font-size: 1px;
  height: 12px;
  position: absolute;
  right: 3px;
  top: 4px;
  width: 12px;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
  background-position: -42px -10px;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice-disabled {
  background-color: #e4e4e4;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  border: 1px solid #ccc;
  color: #666;
  padding-right: 5px;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice-focus {
  background: #d4d4d4;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
  background-position: -42px -10px;
}

.gform_wrapper .chosen-container-multi .chosen-results {
  margin: 0;
  padding: 0;
}

.gform_wrapper .chosen-container-multi .chosen-drop .result-selected {
  color: #ccc;
  cursor: default;
  display: list-item;
}

/* @end */
/* @group Active  */
.gform_wrapper .chosen-container-active .chosen-single {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border: 1px solid #5897fb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single {
  -webkit-box-shadow: 0 1px 0 #fff inset;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #eee), color-stop(80%, #fff));
  background-image: linear-gradient(#eee 20%, #fff 80%);
  border: 1px solid #aaa;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 1px 0 #fff inset;
}

.gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single div {
  background: transparent;
  border-left: 0;
}

.gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -18px 2px;
}

.gform_wrapper .chosen-container-active .chosen-choices {
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border: 1px solid #5897fb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.gform_wrapper .chosen-container-active .chosen-choices li.search-field input[type="text"] {
  color: #222 !important;
}

/* @end */
/* @group Disabled Support */
.gform_wrapper .chosen-disabled {
  cursor: default;
  opacity: 0.5 !important;
}

.gform_wrapper .chosen-disabled .chosen-single {
  cursor: default;
}

.gform_wrapper .chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}

/* @end */
/* @group Right to Left */
.gform_wrapper .chosen-rtl {
  text-align: right;
}

.gform_wrapper .chosen-rtl .chosen-single {
  overflow: visible;
  padding: 0 8px 0 0;
}

.gform_wrapper .chosen-rtl .chosen-single span {
  direction: rtl;
  margin-left: 26px;
  margin-right: 0;
}

.gform_wrapper .chosen-rtl .chosen-single-with-deselect span {
  margin-left: 38px;
}

.gform_wrapper .chosen-rtl .chosen-single div {
  left: 3px;
  right: auto;
}

.gform_wrapper .chosen-rtl .chosen-single abbr {
  left: 26px;
  right: auto;
}

.gform_wrapper .chosen-rtl .chosen-choices li {
  float: right;
}

.gform_wrapper .chosen-rtl .chosen-choices li.search-field input[type="text"] {
  direction: rtl;
}

.gform_wrapper .chosen-rtl .chosen-choices li.search-choice {
  margin: 3px 5px 3px 0;
  padding: 3px 5px 3px 19px;
}

.gform_wrapper .chosen-rtl .chosen-choices li.search-choice .search-choice-close {
  left: 4px;
  right: auto;
}

.gform_wrapper .chosen-rtl.chosen-container-single .chosen-results {
  margin: 0 0 4px 4px;
  padding: 0 4px 0 0;
}

.gform_wrapper .chosen-rtl .chosen-results li.group-option {
  padding-left: 0;
  padding-right: 15px;
}

.gform_wrapper .chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
  border-right: 0;
}

.gform_wrapper .chosen-rtl .chosen-search input[type="text"] {
  background: url("chosen-sprite.png") no-repeat -30px -20px;
  direction: rtl;
  padding: 4px 5px 4px 20px;
}

.gform_wrapper .chosen-rtl.chosen-container-single .chosen-single div b {
  background-position: 6px 2px;
}

.gform_wrapper .chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b {
  background-position: -12px 2px;
}

/* @end */
/* @group Retina compatibility */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-resolution: 144dpi),
only screen and (min-resolution: 1.5dppx) {

  .gform_wrapper .chosen-rtl .chosen-search input[type="text"],
  .gform_wrapper .chosen-container-single .chosen-single abbr,
  .gform_wrapper .chosen-container-single .chosen-single div b,
  .gform_wrapper .chosen-container-single .chosen-search input[type="text"],
  .gform_wrapper .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .gform_wrapper .chosen-container .chosen-results-scroll-down span,
  .gform_wrapper .chosen-container .chosen-results-scroll-up span {
    background-image: url("chosen-sprite@2x.png") !important;
    background-repeat: no-repeat !important;
    background-size: 52px 37px !important;
  }
}

/* @end */

/* updated chosen 1.8.5 styles - gravityforms specific  */

body .gform_wrapper .chosen-container a.chosen-single {
  border-radius: inherit;
}

body .gform_wrapper ul.chosen-results li {
  line-height: inherit;
}

@media only screen and (max-width: 641px) {

  /* make the inputs a little larger for mobile devices */

  .gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='image']):not([type='file']) {
    line-height: 2;
    min-height: 2rem;
  }

  .gform_wrapper textarea {
    line-height: 1.5;
  }

  .gform_wrapper .chosen-container.chosen-container-single[style] {
    width: 100% !important;
  }

  .gform_wrapper .chosen-container-single .chosen-single {
    height: 40px;
    line-height: 2.8;
  }

  .gform_wrapper .chosen-container-single .chosen-single div b {
    position: relative;
    top: 10px;
  }

  .gform_wrapper div.chosen-container.chosen-container-multi[style] {
    width: 100% !important;
  }

  .gform_wrapper .chosen-container.chosen-container-multi ul.chosen-choices li.search-choice,
  .gform_wrapper .chosen-container.chosen-container-multi ul.chosen-choices li.search-field {
    float: none;
    line-height: inherit;
    width: 100%;
  }

  .gform_wrapper .chosen-container.chosen-container-multi ul.chosen-choices li.search-choice span {
    margin-bottom: 0;
  }

  .gform_wrapper .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    top: calc(50% - 6px);
  }

  .gform_wrapper .ginput_container span:not(.ginput_price) {
    display: block;
    margin-bottom: 8px;
  }

  .gform_wrapper li.field_sublabel_below .ginput_complex {
    margin-top: 12px !important;
  }

  .gform_wrapper ul.gfield_radio li label,
  .gform_wrapper ul.gfield_checkbox li label {
    cursor: pointer;
    width: 85%;
  }

}

@media only screen and (max-width: 761px),
(min-device-width: 768px) and (max-device-width: 1023px) {

  .gform_wrapper ul.gform_fields li.gfield.gfield_error+li.gfield.gfield_error {
    margin-top: 32px;
  }

  /* force the list table to not behave like a table anymore */

  .gform_wrapper table.gfield_list {
    border: 0;
  }

  .gform_wrapper table.gfield_list thead {
    left: -9999px;
    position: absolute;
    top: -9999px;
  }

  .gform_wrapper table.gfield_list tr {
    background: #fff;
    border: 1px solid #ccc;
    border-bottom: 4px solid #ccc;
    border-top: 2px solid #ccc;
    display: block;
    margin-bottom: 10px;
    position: relative;
  }

  .gform_wrapper table.gfield_list td {
    display: block;
    margin-left: 8px;
    margin-right: 8px;
  }

  .gform_wrapper table.gfield_list td.gfield_list_icons {
    border-bottom: 0;
    margin: 16px 0 0 0 !important;
  }

  .gform_wrapper ul.gform_fields.form_sublabel_above table.gfield_list td:before,
  .gform_wrapper ul.gform_fields.form_sublabel_below table.gfield_list td:after {
    clear: both;
    content: attr(data-label);
    display: block;
    font-size: .875em;
    letter-spacing: 0.5pt;
    white-space: nowrap;
  }

  .gform_wrapper ul.gform_fields.form_sublabel_above table.gfield_list td:before {
    margin: 8px 0 3px 8px;
  }

  .gform_wrapper ul.gform_fields.form_sublabel_below table.gfield_list td:after {
    margin: 3px 0 8px 8px;
  }

  .gform_wrapper table.gfield_list th+th,
  .gform_wrapper table.gfield_list td+td {
    padding: 4px 0 0 0;
  }

  .gform_wrapper table.gfield_list tr.gfield_list_row_odd,
  .gform_wrapper table.gfield_list tr.gfield_list_row_even {
    margin: 16px 0;
  }

  .gform_wrapper table.gfield_list tr.gfield_list_row_odd:first-child {
    padding-top: 12px;
  }

  .gform_wrapper table.gfield_list tr {
    padding-top: 12px;
  }

  .gform_wrapper table.gfield_list tr td.gfield_list_icons {
    background-color: #eee;
    padding: 0 4px 4px 4px !important;
    width: 100% !important;
  }

  .gform_wrapper table.gfield_list tr td.gfield_list_icons img.add_list_item {
    margin: 12px 0 0 16px !important;
  }

  .gform_wrapper table.gfield_list tr td.gfield_list_icons img.delete_list_item {
    margin: 12px 0 0 8px !important;
  }

  .gform_wrapper .gform_footer a.gform_save_link,
  .gform_wrapper .gform_page_footer a.gform_save_link {
    margin-left: 0;
  }

  .gform_wrapper .gfield_list td.gfield_list_cell input {
    margin-left: 8px;
    margin-right: 8px;
    width: calc(100% - 16px);
  }

}

/* media queries - portrait mode tablet or desktop styles */

@media only screen and (min-width: 641px) {

  .gform_wrapper .gform_body {
    width: 100%;
  }

  .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
    max-width: calc(100% - 16px) !important;
  }

  .gform_wrapper .gfield_checkbox li,
  .gform_wrapper .gfield_radio li {
    line-height: 1.3;
    margin: 0 0 8px 0;
  }

  .gform_wrapper .gsection {
    margin: 28px 16px 28px 0;
  }

  .gform_wrapper ul.gform_fields:not(.top_label) .gfield_label {
    float: left;
    margin-bottom: 16px;
    padding-right: 16px;
    width: 29%;
  }

  .gform_wrapper .right_label .gfield_label {
    text-align: right;
  }

  .gform_wrapper .left_label .gfield_description,
  .gform_wrapper .right_label .gfield_description {
    width: 70%;
  }

  .gform_wrapper ul.right_label li ul.gfield_radio li,
  .gform_wrapper form ul.right_label li ul.gfield_radio li,
  .gform_wrapper ul.left_label li ul.gfield_radio li,
  .gform_wrapper form ul.left_label li ul.gfield_radio li,
  .gform_wrapper ul.right_label li ul.gfield_checkbox li,
  .gform_wrapper form ul.right_label li ul.gfield_checkbox li,
  .gform_wrapper ul.left_label li ul.gfield_checkbox li,
  .gform_wrapper form ul.left_label li ul.gfield_checkbox li {
    margin-bottom: 10px;
  }

  .gform_wrapper .right_label .gfield_description,
  .gform_wrapper .left_label .gfield_description,
  .gform_wrapper .left_label .instruction,
  .gform_wrapper .right_label .instruction {
    margin-left: 29%;
  }

  .gform_wrapper ul.gform_fields:not(.top_label) .gsection .gsection_description {
    line-height: 1.5;
    margin-left: 0;
    padding-left: 0;
    padding-top: 4px;
  }

  .gform_wrapper form ul.gform_fields:not(.top_label) li.gfield_html_formatted {
    margin-left: 29% !important;
    width: 70%;
  }

  .gform_wrapper ul.gform_fields:not(.top_label) ul.gfield_checkbox,
  .gform_wrapper ul.gform_fields:not(.top_label) ul.gfield_radio {
    overflow: hidden;
  }

  .gform_wrapper .right_label div.ginput_complex,
  .gform_wrapper .left_label div.ginput_complex {
    margin-left: 29%;
    width: 70%;
  }

  .gform_wrapper .right_label input.small,
  .gform_wrapper .right_label select.small,
  .gform_wrapper .left_label input.small,
  .gform_wrapper .left_label select.small {
    width: 15%;
  }

  .gform_wrapper .right_label input.medium,
  .gform_wrapper .right_label select.medium,
  .gform_wrapper .left_label input.medium,
  .gform_wrapper .left_label select.medium {
    width: calc(35% - 8px);
  }

  .gform_wrapper .right_label input.large,
  .gform_wrapper .right_label select.large,
  .gform_wrapper .left_label input.large,
  .gform_wrapper .left_label select.large {
    width: 70%;
  }

  .gform_wrapper .right_label textarea.small,
  .gform_wrapper .left_label textarea.small,
  .gform_wrapper .right_label textarea.medium,
  .gform_wrapper .left_label textarea.medium,
  .gform_wrapper .right_label textarea.large,
  .gform_wrapper .left_label textarea.large {
    width: 70%;
  }

  .gform_wrapper ul.gform_fields:not(.top_label) .ginput_list {
    margin-left: 29% !important;
    width: 70%;
  }

  .gform_wrapper .top_label .gfield_list {
    width: 100%;
  }

  .gform_wrapper .left_label .gf_list_one_column,
  .gform_wrapper .right_label .gf_list_one_column {
    width: 45%;
  }

  .gform_wrapper .top_label .gf_list_one_column {
    width: 46%;
  }

  .gform_wrapper .left_label li.hidden_label input,
  .gform_wrapper .right_label li.hidden_label input {
    margin-left: 3.7%;
  }

  .gform_wrapper .left_label li.gfield .gfield_password_strength,
  .gform_wrapper .right_label li.gfield .gfield_password_strength {
    margin-left: 29%;
  }

  .gform_wrapper .top_label input.small,
  .gform_wrapper .top_label select.small {
    width: 25%;
  }

  .gform_wrapper .top_label input.medium,
  .gform_wrapper .top_label select.medium {
    width: calc(50% - 8px);
  }

  .gform_wrapper.gform_validation_error .top_label input.medium,
  .gform_wrapper.gform_validation_error .top_label select.medium {
    width: calc(50% - 8px);
  }

  .gform_wrapper .top_label input.large,
  .gform_wrapper .top_label select.large,
  .gform_wrapper .hidden_label input.large,
  .gform_wrapper .hidden_label select.large {
    width: 100%;
  }

  .gform_wrapper .top_label input.small.datepicker,
  .gform_wrapper .top_label input.medium.datepicker,
  .gform_wrapper .top_label input.large.datepicker {
    width: auto;
  }

  .gform_wrapper textarea.small {
    height: 80px;
    width: 100%;
  }

  .gform_wrapper textarea.medium {
    height: 160px;
    width: 100%;
  }

  .gform_wrapper textarea.large {
    height: 320px;
    width: 100%;
  }

  .gform_wrapper .ginput_complex .ginput_left,
  .gform_wrapper .ginput_complex .ginput_right {
    display: -moz-inline-stack;
    display: inline-block;
    margin: 0 -4px 0 0;
    vertical-align: top;
    width: 50%;
  }

  .gform_wrapper .ginput_container_password .ginput_right {
    padding-left: 8px;
  }

  .gform_wrapper .gfield_error .ginput_complex .ginput_left,
  .gform_wrapper .gfield_error .ginput_complex .ginput_right {
    width: 50%;
  }

  .gform_wrapper .ginput_complex .ginput_cardinfo_left,
  .gform_wrapper .ginput_complex .ginput_cardinfo_right {
    float: left;
    min-height: 43px;
    position: relative;
  }

  .gform_wrapper .ginput_complex .ginput_cardinfo_left {
    width: 50%;
  }

  .gform_wrapper .ginput_complex select.ginput_card_expiration.ginput_card_expiration_month,
  .gform_wrapper .ginput_complex select.ginput_card_expiration.ginput_card_expiration_year {
    display: -moz-inline-stack;
    display: inline-block;
    width: 47% !important;
  }

  .gform_wrapper .ginput_complex .ginput_cardinfo_left select.ginput_card_expiration.ginput_card_expiration_month {
    margin-right: 4px;
  }

  .gform_wrapper .gf_step {
    display: -moz-inline-stack;
    display: inline-block;
    margin: 16px 32px 16px 0;
    width: auto;
  }

  .gform_wrapper .gform_footer input.button,
  .gform_wrapper .gform_footer input[type="submit"],
  .gform_wrapper .gform_footer input[type="image"] {
    display: -moz-inline-stack;
    display: inline-block;
  }

  .gform_wrapper .gform_footer input[type="image"] {
    vertical-align: middle;
  }

  .gform_wrapper .gform_footer a.gform_save_link,
  .gform_wrapper .gform_page_footer a.gform_save_link {
    display: -moz-inline-stack;
    display: inline-block;
    font-size: 1em;
    margin-left: 16px;
  }

  .gform_wrapper .gform_footer input.button,
  .gform_wrapper .gform_page_footer input.button,
  .gform_wrapper .gform_footer input[type="submit"],
  .gform_wrapper .gform_page_footer input[type="submit"] {
    font-size: 1em;
    margin: 0 16px 0 0;
    width: auto;
  }

  .gform_wrapper ul.gform_fields li.gfield {
    padding-right: 16px;
  }

  .gform_wrapper .ginput_complex .ginput_left {
    padding-right: 8px;
  }

  .gform_wrapper .left_label .ginput_complex.ginput_container_address:after {
    content: "";
    display: block;
  }

  .gform_wrapper .ginput_complex.ginput_container_address .ginput_left {
    margin-right: 8px;
  }

  .gform_wrapper .ginput_complex.ginput_container_address .ginput_right {
    margin-right: -8px;
    padding-right: 8px;
  }

  .gform_wrapper .ginput_complex.ginput_container_address span.ginput_full+span.ginput_right {
    margin-right: 8px;
    padding-right: 8px !important;
  }

  .gform_wrapper .ginput_complex.ginput_container_address span.ginput_right+span.ginput_left {
    margin-right: -8px;
    padding-right: 8px !important;
  }

  .gform_wrapper .ginput_complex.ginput_container_address span.ginput_right+span.ginput_left.address_zip {
    margin-right: 8px;
  }

  .gform_wrapper .gfield_error .ginput_container {
    padding-right: 0;
  }

  .gform_wrapper ul.top_label li.gfield.gfield_error.gfield_contains_required .gfield_password_strength {
    margin: 18px 0 18px 0;
  }

  .gform_wrapper .gform_footer:not(.top_label) {
    margin-left: 29%;
    padding: 16px 0 10px 0;
    width: 70%;
  }

  /* adjusting the name field so it aligns properly */

  .top_label div.ginput_complex.ginput_container.gf_name_has_1,
  .top_label div.ginput_complex.ginput_container.gf_name_has_2,
  .top_label div.ginput_complex.ginput_container.gf_name_has_3 {
    width: calc(100% + 15px);
  }

  .top_label div.ginput_complex.ginput_container.gf_name_has_4 {
    width: calc(100% + 13px);
  }

  .top_label div.ginput_complex.ginput_container.gf_name_has_5 {
    width: calc(100% + 14px);
  }

  /* a little better specificty to avoid problems with forms in widgets */

  ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_1,
  ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_2,
  ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_3 {
    margin-left: 29%;
    width: calc(70% + 15px);
  }

  ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_1,
  ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_2,
  ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_3 {
    width: calc(70% + 24px);
  }

  ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_4 {
    margin-left: 29%;
    width: calc(70% + 13px);
  }

  ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_5 {
    margin-left: 29%;
    width: calc(70% + 14px);
  }

  div.ginput_container_name span {
    display: -moz-inline-stack;
    display: inline-block;
    margin-right: -4px;
    padding-right: 16px;
    vertical-align: top;
  }

  div.ginput_complex.ginput_container.gf_name_has_1 span {
    width: 100%;
  }

  div.ginput_complex.ginput_container.gf_name_has_2 span {
    width: 50%;
  }

  div.ginput_complex.ginput_container.gf_name_has_3 span {
    width: 33.3%;
  }

  div.ginput_complex.ginput_container.gf_name_has_4 span {
    width: 25%;
  }

  div.ginput_complex.ginput_container.gf_name_has_5 span {
    width: 19.95%;
  }

  /* tweak the sizes for validation and left/right label layouts */

  .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_1 span {
    width: 99.75% !important;
  }

  .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_2 span {
    width: 49.75% !important;
  }

  .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_3 span {
    width: 33% !important;
  }

  .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_4 span {
    width: 24.75% !important;
  }

  .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_5 span {
    width: 19.75% !important;
  }

  html div.ginput_complex.ginput_container.gf_name_has_2 span:first-child,
  html div.ginput_complex.ginput_container.gf_name_has_3 span:first-child,
  html div.ginput_complex.ginput_container.gf_name_has_4 span:first-child,
  html div.ginput_complex.ginput_container.gf_name_has_5 span:first-child {
    margin-left: 0 !important;
  }

  .gform_wrapper .top_label .ginput_complex.ginput_container.ginput_container_email {
    width: calc(100% + 15px);
  }

  .gform_wrapper ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.ginput_container_email {
    margin-left: 29%;
    width: calc(70% + 16px);
  }

  .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.ginput_container_email {
    width: calc(70% + 17px);
  }

  li.gfield.gfield_error .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error .ginput_complex.ginput_container.ginput_container_email {
    width: calc(70% + 8px) !important;
  }

  .gform_wrapper .ginput_complex.ginput_container.ginput_container_email .ginput_left,
  .gform_wrapper .ginput_complex.ginput_container.ginput_container_email .ginput_right {
    padding-right: 16px;
  }

  .gform_wrapper ul.left_label li.gfield .gfield_password_strength,
  .gform_wrapper ul.right_label li.gfield .gfield_password_strength {
    width: 70%;
  }

  /* adjusting the address field alignment for some scenarios */

  .gform_wrapper .ginput_complex span.ginput_left+input.gform_hidden+span.ginput_left {
    padding-right: 0;
  }

  .gform_wrapper .ginput_complex span.ginput_left.address_country {
    margin-right: 0;
  }

  html:not([dir="rtl"]) .gform_wrapper ul.gform_fields:not(.top_label) .ginput_container:not(.ginput_container_time):not(.ginput_container_date),
  html:not([dir="rtl"]) .gform_wrapper ul.gform_fields:not(.top_label) .clear-multi,
  html:not([dir="rtl"]) .gform_wrapper ul.gform_fields:not(.top_label) .gfield_description {
    margin-left: 29%;
    width: 70%;
  }

  .gform_wrapper .gf_progressbar {
    width: calc(100% - 16px);
  }

  .gform_wrapper ul.gform_fields:not(.top_label) .gform_fileupload_multifile .gform_drop_area {
    margin-left: 29%;
    width: 70%
  }

  body .gform_wrapper span.ginput_left.address_city+input.gform_hidden+span.ginput_left.address_country {
    margin-right: 0;
    width: calc(50% - 8px);
  }

  /* force the CAPTCHA field over for right and left label layouts */

  .gform_wrapper ul.gform_fields:not(.top_label) .ginput_container.ginput_recaptcha {
    margin-left: 29% !important;
  }

  .gform_wrapper .gform_page_footer .button.gform_previous_button {
    margin-bottom: 0;
  }

  .gform_wrapper .gform_page_footer .button.gform_next_button,
  .gform_wrapper .gform_page_footer .button.gform_button {
    margin-right: 10px;
  }

  /* updated chosen 1.8.5 styles - gravityforms specific  */

  body .gform_wrapper select.medium.gfield_select+div.chosen-container-single[style],
  body .gform_wrapper select.medium.gfield_select+div.chosen-container-multi[style] {
    width: calc(50% - 8px) !important;
  }

  body .gform_wrapper select.large.gfield_select+div.chosen-container-single[style],
  body .gform_wrapper select.large.gfield_select+div.chosen-container-multi[style] {
    width: 100% !important;
  }

  body .gform_wrapper select.small.gfield_select+div.chosen-container-single[style],
  body .gform_wrapper select.small.gfield_select+div.chosen-container-multi[style] {
    width: 25% !important;
  }

}

/* //////////////////////////////////////////////////////////////*/
/* //////////////////   Repeaters Styling   /////////////////////*/
/* //////////////////////////////////////////////////////////////*/


/* Padding, Left Border, background color for nested repeaters */
.gfield_repeater_cell>.gfield_repeater_wrapper {
  background-color: rgba(1, 1, 1, 0.02);
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  border-left: 8px solid rgba(54, 86, 102, 0.1);
  border-radius: 8px;
  padding: 10px 20px;

}


/* Targeting the border color of the repeater inputs */
.gfield_repeater_wrapper input {
  border: 1px solid rgba(197, 198, 197, 1);
  border-radius: 4px;
}

/* Spacing between the wrappers */
.gfield_repeater_wrapper .gfield_repeater_wrapper {
  margin-top: 10px;
}

.gfield_repeater_buttons .add_repeater_item_text,
.gfield_repeater_buttons .remove_repeater_item_text {
  background: rgba(242, 242, 242, 0.5);
  border: 1px solid rgba(117, 117, 117, 0.4);
  border-radius: 20px;
  color: rgba(117, 117, 117, 1);
  font-size: 12px;
  font-weight: 400;
  height: 30px;
  margin-bottom: 5px;
  margin-right: 10px;
  min-width: 100px;
  transition: all .3s cubic-bezier(0.67, 0.17, 0.4, 0.83);
}


.gfield_repeater_buttons .add_repeater_item_plus,
.gfield_repeater_buttons .remove_repeater_item_minus {
  background: rgba(242, 242, 242, 0.5);
  border: 1px solid rgba(117, 117, 117, 0.4);
  border-radius: 50%;
  color: rgba(117, 117, 117, 1);
  font-size: 16px;
  font-weight: 700;
  height: 22px;
  margin: 10px 5px 0 5px;
  padding: 0;
  padding-bottom: 5px;
  transition: all .3s cubic-bezier(0.67, 0.17, 0.4, 0.83);
  width: 22px;
}

.gfield_repeater_buttons button.gfield_icon_disabled {
  -khtml-opacity: .3;
  -moz-opacity: .3;
  cursor: default;
  filter: alpha(opacity=30);
  opacity: .3;
}

.gfield_repeater_buttons button.gfield_icon_disabled:hover {
  background: rgba(242, 242, 242, 0.5);
  border: 1px solid rgba(117, 117, 117, 0.4);
  color: rgba(117, 117, 117, 1);
}

/* Hover style for Add buttons */
.gfield_repeater_buttons button:hover {
  background: rgba(250, 250, 250, 1);
  border: 1px solid rgba(117, 117, 117, 1);
  color: #374750;
}


/* Adding the separation border and padding in between items */
.gfield_repeater .gfield_repeater_items .gfield_repeater_item:not(:last-child) {
  border-bottom: 2px solid #e0e0e6;
  padding-bottom: 20px;
}

/* Repeater - Left Border Color */
.gfield_repeater_cell>.gfield_repeater_wrapper {
  border-left: 8px solid rgba(241, 241, 241, 1);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 1px -1px rgba(0, 0, 0, 0.06), 0 1px 5px 0 rgba(0, 0, 0, 0.06);

}

/* Adjusting the margin/padding for the nested repeaters */


/* //////////////////////////////////////////////////////////////*/
/* /////////////////////// Form Edits Labels  ///////////////////////*/
/* //////////////////////////////////////////////////////////////*/


.gfield_repeater_buttons .add_repeater_item_text:focus,
.gfield_repeater_buttons .remove_repeater_item_text:focus {
  outline: 0;
}


/*Coloring for the required asterisk*/
.gfield_repeater_item .gfield_repeater_cell .gfield_required {
  color: #c32121;
}

/* Field labels styling */
.gform_wrapper .gfield_repeater_cell label {
  color: rgb(155, 154, 154);
  font-size: 14px;
  font-weight: 400;
  padding-top: 8px;
}


/* //////////////////////////////////////////////////////////////*/
/* /////////////////////// Forms Details  ///////////////////////*/
/* //////////////////////////////////////////////////////////////*/



/* Third Level Repeater Spacing */
.gfield_repeater_value .gfield_repeater_value .gfield_repeater_cell {
  padding-bottom: 20px;
  padding-top: 0;
}

/*Spacing between adjancent cells in the same repeater*/
div .gfield_repeater_cell {
  margin-top: 5px;
}

/* Repeater styling line divider */
.gfield_repeater .gfield_repeater_items .gfield_repeater_item:not(:last-child) {
  border-bottom: 2px solid #e0e0e6;
  margin-bottom: 20px;
  margin-right: 10px;
  padding-bottom: 20px;
}


/* Repeater containers */
.gfield_repeater_value>.gfield_repeater {
  background-color: rgba(1, 1, 1, 0.02);
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  border-left: 8px solid rgba(54, 86, 102, 0.1);
  border-radius: 8px;
  margin: 10px;
  padding: 10px 20px;
}

/* Styling for Fake disabled input containers */
.gfield_repeater_cell .gfield_repeater_value:not(:first-child) {
  background-color: rgba(240, 240, 240, 1);
  border: 1px solid rgba(197, 198, 197, 1);
  border-radius: 4px;
  color: rgba(117, 117, 117, 0.7);
  margin-right: 10px;
  padding-left: 10px;
}



/* Consistent Padding in between cells and items */
.gfield_repeater_items .gfield_repeater_cell:not(:first-child),
.gfield_repeater .gfield_repeater_items {
  padding-top: 5px;
}

/* Repeaters Labels styling */
.gfield_repeater .gfield_label {
  color: rgba(35, 40, 45, 1);
  font-size: 16px;
  font-weight: 600;
}

/* Decreasing the space below the label */
.gfield_repeater_value .gfield_label {
  padding-bottom: 0;
}

/* Removing additional 20px padding when more repeaters are added on third level repeater */
.gfield_repeater_value .gfield_repeater_value .gfield_repeater_item:first-child {
  padding-bottom: 0;
}


/*
----------------------------------------------------------------

readyclass.css

----------------------------------------------------------------
*/

@media only screen and (min-width: 641px) {

  .gform_wrapper .top_label li.gfield.gf_inline {
    display: -moz-inline-stack;
    display: inline-block;
    float: none !important;
    margin: 0;
    padding-right: 16px;
    vertical-align: top;
    width: auto !important;
  }

  .gform_wrapper .top_label li.gfield.gf_inline input[type="text"].large,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="url"].large,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="email"].large,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="tel"].large,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="number"].large,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="password"].large,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="text"].medium,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="url"].medium,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="email"].medium,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="tel"].medium,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="number"].medium,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="password"].medium,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="text"].small,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="url"].small,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="email"].small,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="tel"].small,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="number"].small,
  .gform_wrapper .top_label li.gfield.gf_inline input[type="password"].small {
    width: 100%;
  }

  .gform_wrapper .top_label li.gfield.gf_inline input[type="text"].datepicker.medium {
    width: 96px !important;
  }

  .gform_wrapper .top_label li.gfield.gf_inline select {
    width: 100%;
  }

  .gform_wrapper .top_label li.gfield.gf_inline textarea {
    width: 100%;
  }

  .gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_hour input[type="text"],
  .gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_minute input[type="text"] {
    width: 70% !important;
  }

  .gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_hour,
  .gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_minute,
  .gform_wrapper .top_label li.gfield.gf_inline div.gfield_date_month,
  .gform_wrapper .top_label li.gfield.gf_inline div.gfield_date_day,
  .gform_wrapper .top_label li.gfield.gf_inline div.gfield_date_year {
    width: 50px;
  }

  .gform_wrapper .top_label li.gfield.gf_inline div.gfield_time_ampm {
    width: auto;
  }

  .gform_wrapper li.gf_inline div.ginput_container {
    white-space: nowrap !important;
  }

  .gform_wrapper li.gf_inline div.ginput_container.ginput_container_date label {
    display: block !important;
  }


  .gform_wrapper li.gfield.gfield_error.gf_inline {
    margin-right: 16px;
    padding-right: 0;
  }

  .gform_wrapper .top_label li.gfield.gf_left_half,
  .gform_wrapper .top_label li.gfield.gf_right_half {
    display: -moz-inline-stack;
    display: inline-block;
    float: none;
    padding-right: 16px;
    vertical-align: top;
    width: 50%;
  }

  .gform_wrapper .top_label li.gfield.gf_left_half .ginput_container:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm):not(.ginput_container_date):not(.ginput_quantity):not(.datepicker):not(.gfield_signature_container),
  .gform_wrapper .top_label li.gfield.gf_right_half .ginput_container:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm):not(.ginput_container_date):not(.ginput_quantity):not(.datepicker):not(.gfield_signature_container) {
    margin: 8px 0 0 0;
    padding-left: 0;
    padding-right: 0;
    width: 100% !important;
  }

  .gform_wrapper .top_label li.gfield.gf_left_half:before {
    clear: both;
    content: "";
    display: block;
  }

  .gform_wrapper .top_label li.gfield.gf_right_half:after {
    clear: both;
    content: "";
    display: table;
  }

  .gform_wrapper.gform_validation_error .top_label li.gfield.gfield_error.gf_left_half,
  .gform_wrapper.gform_validation_error .top_label li.gfield.gfield_error.gf_right_half {
    max-width: calc(50% - 16px);
    padding-right: 0;
  }

  .gform_wrapper .top_label li.gfield.gfield_error.gf_left_half {
    margin-right: 16px;
  }

  .gform_wrapper .top_label li.gfield.gf_left_half div:not(.ginput_container_date) input.medium,
  .gform_wrapper .top_label li.gfield.gf_left_half div:not(.ginput_container_date) input.large,
  .gform_wrapper .top_label li.gfield.gf_left_half div:not(.ginput_container_date) select.medium,
  .gform_wrapper .top_label li.gfield.gf_left_half div:not(.ginput_container_date) select.large,
  .gform_wrapper .top_label li.gfield.gf_right_half div:not(.ginput_container_date) input.medium,
  .gform_wrapper .top_label li.gfield.gf_right_half div:not(.ginput_container_date) input.large,
  .gform_wrapper .top_label li.gfield.gf_right_half div:not(.ginput_container_date) select.medium,
  .gform_wrapper .top_label li.gfield.gf_right_half div:not(.ginput_container_date) select.large {
    width: 100%;
  }

  .gform_wrapper .top_label li.gfield.gf_left_half textarea,
  .gform_wrapper .top_label li.gfield.gf_right_half textarea {
    width: 98%;
  }

  .gform_wrapper .top_label li.gfield.gf_left_half input.small,
  .gform_wrapper .top_label li.gfield.gf_right_half input.small {
    width: 35%;
  }

  .gform_wrapper .top_label li.gfield.gf_left_half+li.gsection,
  .gform_wrapper .top_label li.gfield.gf_right_half+li.gsection {
    padding: 16px 0 8px 0;
  }

  .gform_wrapper .top_label li.gfield.gf_left_half+li.gfield.gsection.gf_left_half,
  .gform_wrapper .top_label li.gfield.gf_right_half+li.gfield.gsection.gf_left_half,
  .gform_wrapper .top_label li.gfield.gf_left_half+li.gfield.gsection.gf_right_half,
  .gform_wrapper .top_label li.gfield.gf_right_half+li.gfield.gsection.gf_right_half,
  .gform_wrapper .top_label li.gfield.gsection.gf_left_half+li.gfield.gsection.gf_left_half,
  .gform_wrapper .top_label li.gfield.gsection.gf_right_half+li.gfield.gsection.gf_left_half,
  .gform_wrapper .top_label li.gfield.gsection.gf_right_half+li.gfield.gsection.gf_right_half {
    padding: 16px 0 8px 0;
  }

  .gform_wrapper .top_label li.gfield.gsection.gf_left_half+li.gfield.gsection.gf_right_half {
    padding: 0 0 8px 0;
  }

  .gform_wrapper .top_label li.gfield.gsection.gf_left_half+li.gfield.gsection.gf_right_half+li.gfield.gsection.gf_left_half+li.gfield.gsection.gf_right_half {
    padding: 16px 0 8px 0;
  }

  .gform_wrapper .top_label li.gfield.gf_left_half+li.gfield,
  .gform_wrapper .top_label li.gfield.gf_right_half+li.gfield,
  .gform_wrapper .top_label li.gfield.gf_left_half+.gform_footer,
  .gform_wrapper .top_label li.gfield.gf_right_half+.gform_footer {
    clear: both;
  }

  /* 3 columns */

  .gform_wrapper .top_label li.gfield.gf_left_third,
  .gform_wrapper .top_label li.gfield.gf_middle_third,
  .gform_wrapper .top_label li.gfield.gf_right_third {
    display: -moz-inline-stack;
    display: inline-block;
    float: none;
    vertical-align: top;
  }

  .gform_wrapper .top_label li.gfield.gf_left_third:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm),
  .gform_wrapper .top_label li.gfield.gf_middle_third:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm) {
    width: 33.3%;
  }

  .gform_wrapper .top_label li.gfield.gf_right_third:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm) {
    width: 33.4%;
  }

  .gform_wrapper .top_label li.gfield.gf_left_third,
  .gform_wrapper .top_label li.gfield.gf_middle_third,
  .gform_wrapper .top_label li.gfield.gf_right_third {
    margin-bottom: 8px;
  }

  .gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) input.medium,
  .gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) input.large,
  .gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) select.medium,
  .gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) select.large,
  .gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) input.medium,
  .gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) input.large,
  .gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) select.medium,
  .gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) select.large,
  .gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) input.medium,
  .gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) input.large,
  .gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) select.medium,
  .gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) select.large {
    width: 100%;
  }

  .gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']):not(.ginput_quantity),
  .gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']):not(.ginput_quantity),
  .gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']):not(.ginput_quantity),
  .gform_wrapper .top_label li.gfield.gf_left_third div:not(.ginput_container_date) select,
  .gform_wrapper .top_label li.gfield.gf_middle_third div:not(.ginput_container_date) select,
  .gform_wrapper .top_label li.gfield.gf_right_third div:not(.ginput_container_date) select {
    width: 100% !important;
  }

  .gform_wrapper .top_label li.gfield.gfield_error.gf_left_third,
  .gform_wrapper .top_label li.gfield.gfield_error.gf_middle_third {
    margin-right: 16px;
    width: calc(33.3% - 24px) !important;
  }

  .gform_wrapper .top_label li.gfield.gfield_error.gf_right_third {
    width: 33.4%;
  }

  .gform_wrapper .top_label li.gfield.gf_right_third:after {
    clear: both;
    content: "";
    display: table;
  }

  .gform_wrapper .top_label li.gfield.gf_left_third+li.gsection,
  .gform_wrapper .top_label li.gfield.gf_right_third+li.gsection {
    padding: 16px 0 8px 0;
  }

  .gform_wrapper .top_label li.gfield.gf_left_third+li.gfield,
  .gform_wrapper .top_label li.gfield.gf_middle_third+li.gfield,
  .gform_wrapper .top_label li.gfield.gf_right_third+li.gfield,
  .gform_wrapper .top_label li.gfield.gf_left_third+.gform_footer,
  .gform_wrapper .top_label li.gfield.gf_middle_third+.gform_footer,
  .gform_wrapper .top_label li.gfield.gf_right_third+.gform_footer {
    clear: both;
  }

  /* 4 columns - quarters */

  .gform_wrapper .top_label li.gfield.gf_first_quarter:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm),
  .gform_wrapper .top_label li.gfield.gf_second_quarter:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm),
  .gform_wrapper .top_label li.gfield.gf_third_quarter:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm),
  .gform_wrapper .top_label li.gfield.gf_fourth_quarter:not(.gfield_time_hour):not(.gfield_time_minute):not(.gfield_time_ampm) {
    display: -moz-inline-stack;
    display: inline-block;
    float: none;
    vertical-align: top;
    width: 25%;
  }

  .gform_wrapper .top_label li.gfield.gf_first_quarter,
  .gform_wrapper .top_label li.gfield.gf_second_quarter,
  .gform_wrapper .top_label li.gfield.gf_third_quarter,
  .gform_wrapper .top_label li.gfield.gf_fourth_quarter {
    margin-bottom: 8px;
  }

  .gform_wrapper .top_label li.gfield.gf_first_quarter div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']),
  .gform_wrapper .top_label li.gfield.gf_second_quarter div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']),
  .gform_wrapper .top_label li.gfield.gf_third_quarter div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']),
  .gform_wrapper .top_label li.gfield.gf_fourth_quarter div:not(.ginput_container_date) input:not([type='radio']):not([type='checkbox']),
  .gform_wrapper .top_label li.gfield.gf_first_quarter div:not(.ginput_container_date) select,
  .gform_wrapper .top_label li.gfield.gf_second_quarter div:not(.ginput_container_date) select,
  .gform_wrapper .top_label li.gfield.gf_third_quarter div:not(.ginput_container_date) select,
  .gform_wrapper .top_label li.gfield.gf_fourth_quarter div:not(.ginput_container_date) select {
    width: 100% !important;
  }

  .gform_wrapper .top_label li.gfield.gfield_error.gf_first_quarter,
  .gform_wrapper .top_label li.gfield.gfield_error.gf_second_quarter,
  .gform_wrapper .top_label li.gfield.gfield_error.gf_third_quarter,
  .gform_wrapper .top_label li.gfield.gfield_error.gf_fourth_quarter {
    width: 24.5%;
  }

  .gform_wrapper .top_label li.gfield.gf_fourth_quarter:after {
    clear: both;
    content: "";
    display: table;
  }

  .gform_wrapper .top_label li.gfield.gf_first_quarter input.medium,
  .gform_wrapper .top_label li.gfield.gf_first_quarter input.large,
  .gform_wrapper .top_label li.gfield.gf_first_quarter select.medium,
  .gform_wrapper .top_label li.gfield.gf_first_quarter select.large,
  .gform_wrapper .top_label li.gfield.gf_second_quarter input.medium,
  .gform_wrapper .top_label li.gfield.gf_second_quarter input.large,
  .gform_wrapper .top_label li.gfield.gf_second_quarter select.medium,
  .gform_wrapper .top_label li.gfield.gf_second_quarter select.large,
  .gform_wrapper .top_label li.gfield.gf_third_quarter input.medium,
  .gform_wrapper .top_label li.gfield.gf_third_quarter input.large,
  .gform_wrapper .top_label li.gfield.gf_third_quarter select.medium,
  .gform_wrapper .top_label li.gfield.gf_third_quarter select.large,
  .gform_wrapper .top_label li.gfield.gf_fourth_quarter input.medium,
  .gform_wrapper .top_label li.gfield.gf_fourth_quarter input.large,
  .gform_wrapper .top_label li.gfield.gf_fourth_quarter select.medium,
  .gform_wrapper .top_label li.gfield.gf_fourth_quarter select.large {
    width: 97.5%;
  }

  .gform_wrapper .top_label li.gfield.gf_first_quarter+li.gsection,
  .gform_wrapper .top_label li.gfield.gf_fourth_quarter+li.gsection {
    padding: 16px 0 8px 0;
  }

  .gform_wrapper .top_label li.gfield.gf_first_quarter+li.gfield,
  .gform_wrapper .top_label li.gfield.gf_second_quarter+li.gfield,
  .gform_wrapper .top_label li.gfield.gf_third_quarter+li.gfield,
  .gform_wrapper .top_label li.gfield.gf_fourth_quarter+li.gfield,
  .gform_wrapper .top_label li.gfield.gf_first_quarter+.gform_footer,
  .gform_wrapper .top_label li.gfield.gf_second_quarter+.gform_footer,
  .gform_wrapper .top_label li.gfield.gf_third_quarter+.gform_footer,
  .gform_wrapper .top_label li.gfield.gf_fourth_quarter+.gform_footer {
    clear: both;
  }

  /* horizontal list columns - displayed inline */

  .gform_wrapper .top_label li ul.gfield_checkbox,
  .gform_wrapper .top_label li ul.gfield_radio {
    width: calc(100% + 16px);
  }

  .gform_wrapper ul.gform_fields:not(.top_label) li ul.gfield_checkbox,
  .gform_wrapper ul.gform_fields:not(.top_label) li ul.gfield_radio {
    width: calc(70% + 16px);
  }

  .gform_wrapper li.gfield.gf_list_2col ul.gfield_checkbox li,
  .gform_wrapper li.gfield.gf_list_2col ul.gfield_radio li {
    display: -moz-inline-stack;
    display: inline-block;
    font-size: 90%;
    margin: 0;
    min-height: 28px;
    padding-right: 16px;
    vertical-align: top;
    width: 50%;
  }

  .gform_wrapper li.gfield.gf_list_3col ul.gfield_checkbox li,
  .gform_wrapper li.gfield.gf_list_3col ul.gfield_radio li {
    display: -moz-inline-stack;
    display: inline-block;
    font-size: 90%;
    margin: 0 0 2px 0;
    min-height: 28px;
    padding-right: 16px;
    vertical-align: top;
    width: 33.3%;
  }

  .gform_wrapper li.gfield.gf_list_2col ul.gfield_checkbox li label,
  .gform_wrapper li.gfield.gf_list_2col ul.gfield_radio li label,
  .gform_wrapper li.gfield.gf_list_3col ul.gfield_checkbox li label,
  .gform_wrapper li.gfield.gf_list_3col ul.gfield_radio li label,
  .gform_wrapper li.gfield.gf_list_4col ul.gfield_checkbox li label,
  .gform_wrapper li.gfield.gf_list_4col ul.gfield_radio li label {
    max-width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
  }

  .gform_wrapper li.gfield.gf_list_4col ul.gfield_checkbox li,
  .gform_wrapper li.gfield.gf_list_4col ul.gfield_radio li {
    display: -moz-inline-stack;
    display: inline-block;
    font-size: 90%;
    margin: 0;
    min-height: 28px;
    padding-right: 16px;
    vertical-align: top;
    width: 25%;
  }

  .gform_wrapper li.gfield.gf_list_5col ul.gfield_checkbox li,
  .gform_wrapper li.gfield.gf_list_5col ul.gfield_radio li {
    display: -moz-inline-stack;
    display: inline-block;
    font-size: 90%;
    margin: 0;
    min-height: 28px;
    padding-right: 16px;
    vertical-align: top;
    width: 20%;
  }

  .gform_wrapper li.gfield.gf_list_2col ul.gfield_checkbox,
  .gform_wrapper li.gfield.gf_list_2col ul.gfield_radio,
  .gform_wrapper li.gfield.gf_list_3col ul.gfield_checkbox,
  .gform_wrapper li.gfield.gf_list_3col ul.gfield_radio,
  .gform_wrapper li.gfield.gf_list_4col ul.gfield_checkbox,
  .gform_wrapper li.gfield.gf_list_4col ul.gfield_radio,
  .gform_wrapper li.gfield.gf_list_5col ul.gfield_checkbox,
  .gform_wrapper li.gfield.gf_list_5col ul.gfield_radio {
    overflow: hidden;
  }

  .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_2col label.gfield_label,
  .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_3col label.gfield_label,
  .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_4col label.gfield_label,
  .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_5col label.gfield_label,
  .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gf_list_inline label.gfield_label {
    margin: 0 0 10px 0;
  }

  /* vertical list columns - using CSS columns */

  .gform_wrapper li.gf_list_2col_vertical .ginput_container_checkbox ul.gfield_checkbox,
  .gform_wrapper li.gf_list_3col_vertical .ginput_container_checkbox ul.gfield_checkbox,
  .gform_wrapper li.gf_list_4col_vertical .ginput_container_checkbox ul.gfield_checkbox,
  .gform_wrapper li.gf_list_5col_vertical .ginput_container_checkbox ul.gfield_checkbox {
    -moz-column-gap: 16px;
    -webkit-column-gap: 16px;
    column-gap: 16px;
  }

  .gform_wrapper ul.gform_fields:not(.top_label) li.gf_list_2col_vertical label.gfield_label,
  .gform_wrapper ul.gform_fields:not(.top_label) li.gf_list_2col_vertical label.gfield_label,
  .gform_wrapper ul.gform_fields:not(.top_label) li.gf_list_2col_vertical label.gfield_label,
  .gform_wrapper ul.gform_fields:not(.top_label) li.gf_list_2col_vertical label.gfield_label {
    margin: 0 0 10px 0;
  }

  .gform_wrapper li.gf_list_2col_vertical .ginput_container_checkbox ul.gfield_checkbox,
  .gform_wrapper li.gf_list_2col_vertical .ginput_container_radio ul.gfield_radio {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    width: calc(100% - 16px);
  }

  .gform_wrapper li.gf_list_3col_vertical .ginput_container_checkbox ul.gfield_checkbox,
  .gform_wrapper li.gf_list_3col_vertical .ginput_container_radio ul.gfield_radio {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
    width: calc(100% - 8px);
  }

  .gform_wrapper li.gf_list_4col_vertical .ginput_container_checkbox ul.gfield_checkbox,
  .gform_wrapper li.gf_list_4col_vertical .ginput_container_radio ul.gfield_radio {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
    width: calc(100% - 8px);
  }

  .gform_wrapper li.gf_list_5col_vertical .ginput_container_checkbox ul.gfield_checkbox,
  .gform_wrapper li.gf_list_5col_vertical .ginput_container_radio ul.gfield_radio {
    -moz-column-count: 5;
    -webkit-column-count: 5;
    column-count: 5;
    width: calc(100% - 8px);
  }

  .gform_wrapper li.gf_list_2col_vertical .ginput_container_checkbox ul.gfield_checkbox li label,
  .gform_wrapper li.gf_list_2col_vertical .ginput_container_radio ul.gfield_radio li label,
  .gform_wrapper li.gf_list_3col_vertical .ginput_container_checkbox ul.gfield_checkbox li label,
  .gform_wrapper li.gf_list_3col_vertical .ginput_container_radio ul.gfield_radio li label,
  .gform_wrapper li.gf_list_4col_vertical .ginput_container_checkbox ul.gfield_checkbox li label,
  .gform_wrapper li.gf_list_4col_vertical .ginput_container_radio ul.gfield_radio li label,
  .gform_wrapper li.gf_list_5col_vertical .ginput_container_checkbox ul.gfield_checkbox li label,
  .gform_wrapper li.gf_list_5col_vertical .ginput_container_radio ul.gfield_radio li label {
    max-width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .gform_wrapper li.gfield.gf_list_height_25 ul.gfield_checkbox li,
  .gform_wrapper li.gfield.gf_list_height_25 ul.gfield_radio li {
    height: 25px;
  }

  .gform_wrapper li.gfield.gf_list_height_50 ul.gfield_checkbox li,
  .gform_wrapper li.gfield.gf_list_height_50 ul.gfield_radio li {
    height: 50px;
  }

  .gform_wrapper li.gfield.gf_list_height_75 ul.gfield_checkbox li,
  .gform_wrapper li.gfield.gf_list_height_75 ul.gfield_radio li {
    height: 75px;
  }

  .gform_wrapper li.gfield.gf_list_height_100 ul.gfield_checkbox li,
  .gform_wrapper li.gfield.gf_list_height_100 ul.gfield_radio li {
    height: 100px;
  }

  .gform_wrapper li.gfield.gf_list_height_125 ul.gfield_checkbox li,
  .gform_wrapper li.gfield.gf_list_height_125 ul.gfield_radio li {
    height: 125px;
  }

  .gform_wrapper li.gfield.gf_list_height_150 ul.gfield_checkbox li,
  .gform_wrapper li.gfield.gf_list_height_150 ul.gfield_radio li {
    height: 150px;
  }

  .gform_wrapper li.gf_list_inline ul.gfield_checkbox li,
  .gform_wrapper li.gf_list_inline ul.gfield_radio li {
    display: -moz-inline-stack;
    display: inline-block;
    float: none !important;
    margin: 0 8px 10px 0;
    width: auto !important;
  }

  html[dir="rtl"] .gform_wrapper li.gf_list_inline ul.gfield_checkbox li,
  html[dir="rtl"] .gform_wrapper li.gf_list_inline ul.gfield_radio li {
    padding-left: 16px !important;
    padding-right: 0 !important;
  }

  .gform_wrapper li.gf_hide_ampm div.gfield_time_ampm {
    display: none !important;
  }

  .gform_wrapper li.gsection.gf_scroll_text {
    background-color: #fff;
    border: 1px solid #ccc;
    border-bottom: 1px solid #ccc !important;
    height: 240px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 16px;
    width: calc(100% - 16px);
  }

  .gform_wrapper .top_label li.gsection.gf_scroll_text h2.gsection_title {
    margin: 10px 10px 0 8px !important
  }

  .gform_wrapper.gf_browser_gecko .top_label li.gsection.gf_scroll_text h2.gsection_title,
  .gform_wrapper.gf_browser_chrome .top_label li.gsection.gf_scroll_text h2.gsection_title {
    margin: 0 10px 0 8px !important;
  }

  .gform_wrapper .top_label li.gsection.gf_scroll_text .gsection_description {
    font-size: 12px;
    margin: 10px;
  }

  .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ul li,
  .gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ul li {
    list-style-type: disc !important;
    margin: 0 0 8px;
    overflow: visible;
    padding-left: 0;
  }

  .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ul,
  .gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ul {
    list-style-type: disc !important;
    margin: 16px 0 16px 18px;
    padding-left: 0;
  }

  .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li,
  .gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li {
    list-style-type: decimal !important;
    margin: 0 0 8px;
    overflow: visible;
    padding-left: 0;
  }

  .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol,
  .gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol {
    list-style-type: decimal !important;
    margin: 16px 0 18px 32px;
    padding-left: 0;
  }

  .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li ul,
  .gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li ul li {
    list-style-type: disc !important;
    padding-left: 0;
  }

  .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li ul {
    margin: 16px 0 16px 18px;
  }

  .gform_wrapper form div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description ol li ul li {
    margin: 0 0 8px;
  }

  .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description dl {
    margin: 0 0 18px 0;
    padding-left: 0;
  }

  .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description dl dt {
    font-weight: 700;
  }

  .gform_wrapper div.gform_ul.gform_fields .top_label li.gfield.gsection.gf_scroll_text div.gsection_description dl dd {
    margin: 0 0 16px 18px;
  }

  .gform_wrapper li.gfield.gfield_html.gf_alert_green,
  .gform_wrapper li.gfield.gfield_html.gf_alert_red,
  .gform_wrapper li.gfield.gfield_html.gf_alert_yellow,
  .gform_wrapper li.gfield.gfield_html.gf_alert_gray,
  .gform_wrapper li.gfield.gfield_html.gf_alert_blue {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    margin: 20px 0 !important;
    padding: 20px !important;
  }

  .gform_wrapper li.gfield.gfield_html.gf_alert_green {
    background-color: #cbeca0;
    border: 1px solid #97b48a;
    color: #030;
    text-shadow: #dfb 1px 1px;
  }

  .gform_wrapper li.gfield.gfield_html.gf_alert_red {
    background-color: #faf2f5;
    border: 1px solid #cfadb3;
    color: #832525;
    text-shadow: #fff 1px 1px;
  }

  .gform_wrapper li.gfield.gfield_html.gf_alert_yellow {
    background-color: #fffbcc;
    border: 1px solid #e6db55;
    color: #222;
    text-shadow: #fcfaea 1px 1px;
  }

  .gform_wrapper li.gfield.gfield_html.gf_alert_gray {
    background-color: #eee;
    border: 1px solid #ccc;
    color: #424242;
    text-shadow: #fff 1px 1px;
  }

  .gform_wrapper li.gfield.gfield_html.gf_alert_blue {
    background-color: #d1e4f3;
    border: 1px solid #a7c2e7;
    color: #314475;
    text-shadow: #e0f1ff 1px 1px;
  }

  div.gform_confirmation_wrapper.gf_confirmation_simple_yellow {
    background-color: #fffbcc;
    border-bottom: 1px solid #e6db55;
    border-top: 1px solid #e6db55;
    color: #424242;
    font-size: 25px;
    margin: 30px 0;
    max-width: 99%;
    padding: 32px;
  }

  div.gform_confirmation_wrapper.gf_confirmation_simple_gray {
    background-color: #eaeaea;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    color: #424242;
    font-size: 25px;
    margin: 30px 0;
    max-width: 99%;
    padding: 32px;
  }

  div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient {
    -moz-box-shadow: 0 0 5px rgba(221, 215, 131, 0.75);
    -webkit-box-shadow: 0 0 5px rgba(221, 215, 131, 0.75);
    background-color: #fffbd2;
    border: 1px solid #e6db55;
    box-shadow: 0 0 5px rgba(221, 215, 131, 0.75);
    margin: 30px 0;
    position: relative;
  }

  div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient div.gforms_confirmation_message {
    background: linear-gradient(to bottom, #fffce5 0%, #fff9bf 100%);
    background: -ms-linear-gradient(top, #fffce5 0%, #fff9bf 100%);
    background: -o-linear-gradient(top, #fffce5 0%, #fff9bf 100%);
    background: -webkit-linear-gradient(top, #fffce5 0%, #fff9bf 100%);
    background: #fffce5;
    background: -moz-linear-gradient(top, #fffce5 0%, #fff9bf 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fffce5), color-stop(100%, #fff9bf));
    background-color: #fffbcc;
    border-bottom: 1px solid #e6db55;
    border-top: 2px solid #fff;
    color: #424242;
    font-size: 28px;
    margin: 0;
    max-width: 99%;
    padding: 40px;
  }

  div.gform_confirmation_wrapper.gf_confirmation_green_gradient {
    -moz-box-shadow: 0 0 5px rgba(86, 122, 86, 0.4);
    -webkit-box-shadow: 0 0 5px rgba(86, 122, 86, 0.4);
    background-color: #f1fcdf;
    border: 1px solid #a7c37c;
    box-shadow: 0 0 5px rgba(86, 122, 86, 0.4);
    margin: 30px 0;
    position: relative;
  }

  div.gform_confirmation_wrapper.gf_confirmation_green_gradient div.gforms_confirmation_message {
    background: linear-gradient(to bottom, rgba(219, 242, 183, 1) 0%, rgba(180, 208, 136, 1) 100%);
    background: -ms-linear-gradient(top, rgba(219, 242, 183, 1) 0%, rgba(180, 208, 136, 1) 100%);
    background: -o-linear-gradient(top, rgba(219, 242, 183, 1) 0%, rgba(180, 208, 136, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(219, 242, 183, 1) 0%, rgba(180, 208, 136, 1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(219, 242, 183, 1)), color-stop(100%, rgba(180, 208, 136, 1)));
    background: rgb(219, 242, 183);
    background: -moz-linear-gradient(top, rgba(219, 242, 183, 1) 0%, rgba(180, 208, 136, 1) 100%);
    background-color: #fffbcc;
    border-bottom: 1px solid #a7c37c;
    border-top: 2px solid #effade;
    color: #030;
    font-size: 28px;
    margin: 0;
    max-width: 99%;
    padding: 40px;
    text-shadow: #dfb 1px 1px;
  }

  div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient:before,
  div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient:after,
  div.gform_confirmation_wrapper.gf_confirmation_green_gradient:before,
  div.gform_confirmation_wrapper.gf_confirmation_green_gradient:after {
    -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
    -moz-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
    -webkit-transform: rotate(-3deg);
    background: rgba(0, 0, 0, 0.2);
    bottom: 15px;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
    content: "";
    left: 10px;
    max-width: 40%;
    position: absolute;
    top: 80%;
    transform: rotate(-3deg);
    width: 50%;
    z-index: -1;
  }

  div.gform_confirmation_wrapper.gf_confirmation_yellow_gradient:after,
  div.gform_confirmation_wrapper.gf_confirmation_green_gradient:after {
    -moz-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -webkit-transform: rotate(3deg);
    left: auto;
    right: 10px;
    transform: rotate(3deg);
  }

  /* simple horizontal form ready class - very simple implementation for up to 5 fields and a button */

  .gform_wrapper.gf_simple_horizontal_wrapper.centered_wrapper {
    display: table;
    margin: 0 auto;
    width: auto;
  }

  .gform_wrapper form.gf_simple_horizontal {
    margin: 0 auto;
    width: calc(100% - 16px);
  }

  .gform_wrapper form.gf_simple_horizontal div.gform_body,
  .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label {
    display: table-cell;
    margin: 0;
    padding: 0;
    position: relative;
    vertical-align: middle;
  }

  .gform_wrapper form.gf_simple_horizontal div.gform_body {
    max-width: 75%;
    width: auto;
  }

  .gform_wrapper form.gf_simple_horizontal div.ginput_container,
  .gform_wrapper form.gf_simple_horizontal ul li.gfield {
    margin-top: 0 !important;
  }

  .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label {
    margin: 0;
    max-width: 25%;
    padding: 0;
    width: auto;
  }

  body:not(rtl) .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label {
    text-align: left
  }

  .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label {
    display: table;
    width: 100%;
  }

  .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield {
    display: table-cell;
    height: auto;
    padding-right: 1em;
  }

  .gform_wrapper form.gf_simple_horizontal label.gfield_label,
  .gform_wrapper form.gf_simple_horizontal .ginput_complex label,
  .gform_wrapper form.gf_simple_horizontal .gfield_description:not(.validation_message) {
    display: block;
    height: 1px;
    left: -9000px;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 1px;
  }

  .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label.form_sublabel_below li.gfield {
    vertical-align: middle;
  }

  .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label.form_sublabel_above li.gfield {
    vertical-align: middle;
  }

  .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield input.small,
  .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield input.medium,
  .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield input.large {
    width: 100%
  }

  .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield select.small,
  .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield select.medium,
  .gform_wrapper form.gf_simple_horizontal div.gform_body ul.top_label li.gfield select.large {
    width: 100%
  }

  .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label input[type="submit"],
  .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label input[type="button"],
  .gform_wrapper form.gf_simple_horizontal div.gform_footer.top_label input[type="image"] {
    height: auto;
  }

  .gform_wrapper form.gf_simple_horizontal .top_label .gfield_error {
    margin-bottom: 0;
  }

  .gform_wrapper form.gf_simple_horizontal ul li.gfield .ginput_container_checkbox ul li,
  .gform_wrapper form.gf_simple_horizontal ul li.gfield .ginput_container_radio ul li {
    display: inline-block;
    margin-right: .5em;
  }

  html[dir="rtl"] .gform_wrapper form.gf_simple_horizontal div.ginput_complex.ginput_container.gf_name_has_2 span {
    width: 48.5%;
  }

  html[dir="rtl"] .gform_wrapper form.gf_simple_horizontal div.ginput_complex.ginput_container.gf_name_has_3 span {
    width: 33%;
  }

  html[dir="rtl"] .gform_wrapper form.gf_simple_horizontal div.ginput_complex.ginput_container.gf_name_has_4 span {
    width: 24.5%;
  }

  html[dir="rtl"] .gform_wrapper form.gf_simple_horizontal div.ginput_complex.ginput_container.gf_name_has_5 span {
    width: 19.5%;
  }


  /* end media query */

}

/* add equal padding to form for better centering */

body .gform_wrapper.gf_form_center_wrapper {
  padding: 16px 0 16px 16px;
}

/*
----------------------------------------------------------------
datepicker.css
----------------------------------------------------------------
*/

.ui-datepicker {
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
  font: 9pt Arial, sans-serif;
  height: auto;
  margin: 5px auto 0;
  width: 216px;
}

.ui-datepicker a {
  text-decoration: none;
}

.ui-datepicker table {
  border-collapse: collapse;
  width: 100%;
}

.ui-datepicker-header {
  -moz-box-shadow: inset 0 1px 1px 0 rgba(250, 250, 250, .2);
  /* set the header background color */
  -webkit-box-shadow: inset 0 1px 1px 0 rgba(250, 250, 250, .2);
  background-color: #666;
  border-color: #666;
  border-style: solid;
  border-width: 1px 0 0 0;
  box-shadow: inset 0 1px 1px 0 rgba(250, 250, 250, .2);
  color: #e0e0e0;
  filter: dropshadow(color=#000, offx=1, offy=-1);
  font-weight: bold;
  line-height: 30px;
  min-height: 30px !important;
  text-shadow: 1px -1px 0 #000;
}

.ui-datepicker-title {
  text-align: center;
}

.ui-datepicker-title select {
  margin-top: 2.5%;
}

.ui-datepicker-prev,
.ui-datepicker-next {
  background-image: url('../images/datepicker/arrow.png');
  background-repeat: no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 600%;
  overflow: hidden;
  text-align: center;
  width: 30px;
}

.ui-datepicker-prev {
  background-position: center -30px;
  float: left;
}

.ui-datepicker-next {
  background-position: center 0;
  float: right;
}

.ui-datepicker thead {
  background: #f7f7f7;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y3ZjdmNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmMWYxZjEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+');
  background: -moz-linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f7f7f7), color-stop(100%, #f1f1f1));
  background: -webkit-linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
  background: -o-linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
  background: -ms-linear-gradient(top, #f7f7f7 0%, #f1f1f1 100%);
  background: linear-gradient(to bottom, #f7f7f7 0%, #f1f1f1 100%);
  border-bottom: 1px solid #bbb;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f7f7', endColorstr='#f1f1f1', GradientType=0);
}

.ui-datepicker th {
  color: #666;
  filter: dropshadow(color=#fff, offx=1, offy=0);
  font-size: 6pt;
  padding: 5px 0;
  text-align: center;
  text-shadow: 1px 0 0 #fff;
  text-transform: uppercase;
}

.ui-datepicker tbody td {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
  padding: 0;
}

.ui-datepicker tbody td:last-child {
  border-right: 0;
}

.ui-datepicker tbody tr {
  border-bottom: 1px solid #bbb;
}

.ui-datepicker tbody tr:last-child {
  border-bottom: 0;
}

.ui-datepicker td span,
.ui-datepicker td a {
  color: #666;
  display: inline-block;
  filter: dropshadow(color=#fff, offx=1, offy=1);
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-shadow: 1px 1px 0 #fff;
  width: 30px;
}

.ui-datepicker-calendar .ui-state-default {
  -moz-box-shadow: inset 1px 1px 0 0 rgba(250, 250, 250, .5);
  -webkit-box-shadow: inset 1px 1px 0 0 rgba(250, 250, 250, .5);
  background: -ms-linear-gradient(top, #ededed 0%, #dedede 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ededed), color-stop(100%, #dedede));
  background: -webkit-linear-gradient(top, #ededed 0%, #dedede 100%);
  background: -o-linear-gradient(top, #ededed 0%, #dedede 100%);
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VkZWRlZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNkZWRlZGUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+');
  background: linear-gradient(to bottom, #ededed 0%, #dedede 100%);
  background: #ededed;
  background: -moz-linear-gradient(top, #ededed 0%, #dedede 100%);
  box-shadow: inset 1px 1px 0 0 rgba(250, 250, 250, .5);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#dedede', GradientType=0);
}

.ui-datepicker-calendar .ui-state-hover {
  background: #f7f7f7;
}

.ui-datepicker-calendar .ui-state-active {
  -moz-box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, .1);
  /* set the active date background color */
  -webkit-box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, .1);
  /* set the active date border color */
  background: #fff2aa;
  /* set the active date font color */
  border: 1px solid #c19163;
  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, .1);
  color: #666;
  filter: dropshadow(color=#fff, offx=0, offy=1);
  margin: -1px;
  position: relative;
  text-shadow: 0 1px 0 #fff;
}

.ui-datepicker-unselectable .ui-state-default {
  background: #f4f4f4;
  color: #b4b3b3;
}

.ui-datepicker-calendar td:first-child .ui-state-active {
  margin-left: 0;
  width: 29px;
}

.ui-datepicker-calendar td:last-child .ui-state-active {
  margin-right: 0;
  width: 29px;
}

.ui-datepicker-calendar tr:last-child .ui-state-active {
  height: 29px;
  margin-bottom: 0;
}

td.ui-datepicker-unselectable.ui-state-disabled {
  background-color: #d7d7d7;
}

table.ui-datepicker-calendar {
  margin: 0 0 0 0 !important;
}

body div#ui-datepicker-div[style] {
  z-index: 9999 !important;
}

/*
----------------------------------------------------------------
browsers.css
----------------------------------------------------------------
*/

/* Safari specific styles */

.entry .gform_wrapper.gf_browser_safari ul li:before,
.entry .gform_wrapper.gf_browser_safari ul li:after {
  content: "";
}

.gform_wrapper.gf_browser_safari .gform_body ul.gform_fields li.gfield .ginput_container #recaptcha_widget_div #recaptcha_area {
  width: 99% !important;
}

.gform_wrapper.gf_browser_safari .left_label #recaptcha_area #recaptcha_table,
.gform_wrapper.gf_browser_safari .right_label #recaptcha_area #recaptcha_table {
  margin-left: 32%;
}

.gform_wrapper.gf_browser_safari .gfield_checkbox li input[type="checkbox"],
.gform_wrapper.gf_browser_safari .gfield_radio li input[type="radio"],
.gform_wrapper.gf_browser_safari .gfield_checkbox li input {
  margin-top: 4px;
}

.gform_wrapper.gf_browser_safari select[multiple="multiple"] {
  height: auto !important;
}

.gform_wrapper.gf_browser_safari input.button.gform_button_select_files {
  padding: 6px 12px !important;
}

.gform_wrapper.gf_browser_safari .gfield_time_ampm select {
  max-width: 5rem !important;
  width: calc(2rem + 20px);
}

/* Google Chrome (and now Opera) styles */

.gform_wrapper.gf_browser_chrome select {
  padding: 2px 0 2px 3px;
}

.gform_wrapper.gf_browser_chrome .gfield_time_ampm select {
  max-width: 5rem !important;
  width: calc(3rem + 20px);
}

.gform_wrapper.gf_browser_chrome ul li:before,
.gform_wrapper.gf_browser_chrome ul li:after,
.entry .gform_wrapper.gf_browser_chrome ul li:before,
.entry .gform_wrapper.gf_browser_chrome ul li:after {
  content: none;
}

.gform_wrapper.gf_browser_chrome .gform_body ul.gform_fields li.gfield .ginput_container #recaptcha_widget_div #recaptcha_area {
  width: 99% !important;
}

.gform_wrapper.gf_browser_chrome .left_label #recaptcha_area #recaptcha_table,
.gform_wrapper.gf_browser_chrome .right_label #recaptcha_area #recaptcha_table {
  margin-left: 32%;
}

.gform_wrapper.gf_browser_chrome .ginput_complex select,
.gform_wrapper.gf_browser_chrome .ginput_complex .ginput_right select {
  line-height: 1.5em;
  margin-bottom: 5px;
  margin-top: 2px;
  text-indent: 2px;
}

.gform_wrapper.gf_browser_chrome .gfield_checkbox li input[type="checkbox"],
.gform_wrapper.gf_browser_chrome .gfield_radio li input[type="radio"],
.gform_wrapper.gf_browser_chrome .gfield_checkbox li input {
  margin-top: 6px;
}

.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield div.ginput_complex span.ginput_left select,
.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield div.ginput_complex span.ginput_right select,
.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
  margin-left: 1px;
}

.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield input[type="radio"] {
  margin-left: 1px;
}

.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_first,
.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_middle,
.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_last {
  padding-top: 2px;
}

.gform_wrapper.gf_browser_chrome select[multiple="multiple"] {
  height: auto !important;
}

.gform_wrapper.gf_browser_chrome .gfield_error .ginput_complex.ginput_container.has_first_name.has_middle_name.has_last_name span.name_middle,
.gform_wrapper.gf_browser_chrome .gfield_error .ginput_complex.ginput_container.has_first_name.has_middle_name.has_last_name span.name_last {
  margin-left: 1.1%;
}

// .gform_wrapper.gf_browser_chrome .gfield_error input[type="text"],
// .gform_wrapper.gf_browser_chrome .gfield_error input[type="email"],
// .gform_wrapper.gf_browser_chrome .gfield_error input[type="tel"],
// .gform_wrapper.gf_browser_chrome .gfield_error input[type="url"],
// .gform_wrapper.gf_browser_chrome .gfield_error input[type="number"],
// .gform_wrapper.gf_browser_chrome .gfield_error input[type="password"],
// .gform_wrapper.gf_browser_chrome .gfield_error select,
// .gform_wrapper.gf_browser_chrome .gfield_error textarea,
// .gform_wrapper.gf_browser_chrome li.gfield_error.field_sublabel_above .ginput_complex input[type="text"] {
//   margin-bottom: 2px;
// }

.gform_wrapper.gf_browser_chrome input.button.gform_button_select_files {
  padding: 6px 12px !important;
}

.gform_wrapper.gf_browser_chrome span.address_country {
  margin-top: -2px;
}

/* Firefox specific styles */

.gform_wrapper.gf_browser_gecko select {
  padding: 2px 12px 2px 2px;
}

.gform_wrapper.gf_browser_gecko .gfield_time_ampm select {
  max-width: 5rem !important;
  width: calc(4rem + 25px);
}

.gform_wrapper.gf_browser_gecko ul li:before,
.gform_wrapper.gf_browser_gecko ul li:after,
.entry .gform_wrapper.gf_browser_gecko ul li:before,
.entry .gform_wrapper.gf_browser_gecko ul li:after {
  content: none;
}

.gform_wrapper.gf_browser_gecko .ginput_complex .ginput_cardinfo_left select.ginput_card_expiration.ginput_card_expiration_month {
  margin-right: 1px;
}

.gform_wrappergf_browser_gecko .chosen-container-single .chosen-single {
  height: 32px;
  line-height: 2.4;
}

.gform_wrappergf_browser_gecko .chosen-container-single .chosen-single div b {
  position: relative;
  top: 5px;
}

/* Internet Explorer specific styles */

.gform_wrapper.gf_browser_ie ul li:before,
.gform_wrapper.gf_browser_ie ul li:after,
.entry .gform_wrapper.gf_browser_ie ul li:before,
.entry .gform_wrapper.gf_browser_ie ul li:after {
  content: none;
}

.gform_wrapper.gf_browser_ie .gfield_time_hour {
  width: 80px;
}

.gform_wrapper.gf_browser_ie .gfield_time_minute {
  width: 70px;
}

.gform_wrapper.gf_browser_ie .gfield_time_ampm {
  padding-top: 2px;
}

.gform_wrapper.gf_browser_ie .gfield_time_hour input,
.gform_wrapper.gf_browser_ie .gfield_time_minute input {
  margin-left: 0;
}

.gform_wrapper.gf_browser_ie .ginput_complex .ginput_left label,
.gform_wrapper.gf_browser_ie .ginput_complex .ginput_right label {
  margin: 3px 0 5px 0;
}

.gform_wrapper.gf_browser_ie .gform_footer input.button {
  padding: 3px;
}

.gform_wrapper.gf_browser_ie ul.top_label .clear-multi {
  clear: both;
  overflow: hidden;
}

.gform_wrapper.gf_browser_ie .gfield_radio li {
  line-height: 20px !important;
}

.gform_wrapper.gf_browser_ie .gfield_checkbox li input[type="checkbox"],
.gform_wrapper.gf_browser_ie .gfield_radio li input[type="radio"],
.gform_wrapper.gf_browser_ie .gfield_checkbox li input {
  margin-top: 0;
}

.gform_wrapper.gf_browser_ie .ginput_complex .ginput_left select,
.gform_wrapper.gf_browser_ie .ginput_complex .ginput_right select {
  padding: 2px 0 2px 0;
}

.gform_wrapper.gf_browser_ie .gform_body ul.gform_fields li.gfield .ginput_container #recaptcha_widget_div #recaptcha_area {
  width: 99% !important;
}

.gform_wrapper.gf_browser_ie .gform_body ul.gform_fields li.gfield .ginput_container #recaptcha_widget_div #recaptcha_area {
  width: 99% !important;
}

.gform_wrapper.gf_browser_ie .left_label #recaptcha_area #recaptcha_table,
.gform_wrapper.gf_browser_ie .right_label #recaptcha_area #recaptcha_table {
  margin-left: 32%;
}

.gform_wrapper.gf_browser_ie .ginput_complex .ginput_cardinfo_right span.ginput_card_security_code_icon {
  left: 4px;
  position: relative;
  top: -1px;
}

.gform_wrapper.gf_browser_ie img.add_list_item,
.gform_wrapper.gf_browser_ie img.delete_list_item {
  background-size: 16px 16px !important;
  height: 16px !important;
  width: 16px !important;
}

.gform_wrapper.gf_browser_iphone ul li:before,
.gform_wrapper.gf_browser_iphone ul li:after,
.entry .gform_wrapper.gf_browser_iphone ul li:before,
.entry .gform_wrapper.gf_browser_iphone ul li:after {
  content: none;
}

.gform_wrapper.gf_browser_unknown ul li:before,
.gform_wrapper.gf_browser_unknown ul li:after,
.entry .gform_wrapper.gf_browser_unknown ul li:before,
.entry .gform_wrapper.gf_browser_unknown ul li:after {
  content: none;
}

@media only screen and (max-width: 641px) {

  /* make the nifty styled selects a little larger for mobile devices */

  .gform_wrapper.gf_browser_gecko .chosen-container-single .chosen-single,
  .gform_wrapper.gf_browser_safari .chosen-container-single .chosen-single,
  .gform_wrapper.gf_browser_chrome .chosen-container-single .chosen-single {
    height: 44px;
    line-height: 3.2;
  }

  .gform_wrapper.gf_browser_gecko .chosen-container-single .chosen-single div b,
  .gform_wrapper.gf_browser_safari .chosen-container-single .chosen-single div b,
  .gform_wrapper.gf_browser_chrome .chosen-container-single .chosen-single div b {
    position: relative;
    top: 50%;
    transform: translateY(-25%);
  }

}
