/* ------------------------------------------------------------ *\
    Section projects
\* ------------------------------------------------------------ */

.section-projects {
    padding-bottom: 16rem;

    @include breakpoint-down(mobile) {
        padding-bottom: 6.7rem;
    }

    .section__head {
        margin-bottom: 6.9rem;

        @include breakpoint-down(mobile) {
            margin-bottom: 5.6rem;
        }
    }

    .section__inner {
        padding: 0.9rem 0 0;
        //border-top: 0.1rem solid $c-grey-3;
		position: relative;
		
        @include breakpoint-down(mobile) {
            padding: 0.6rem 0 0;
        }
    }
    
    .section__inner::before {
	  content: '';
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 0;
	  height: 0.1rem; /* adjust to your needs */
	  background-color: $c-grey-3; /* your keyline color */
	  transition: width 0.5s ease-in-out;
	}
	
	.section__inner.animate::before {
	  width: 100%;
	}
    
    
}