/* ------------------------------------------------------------ *\
    List links
\* ------------------------------------------------------------ */

.list-links {
    list-style-type: none;
    padding-left: 0;

    a {
        display: block;
    }
}

.list-links + .list-links {
    margin-top: 2rem;
}