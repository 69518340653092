/* ------------------------------------------------------------ *\
    Features
\* ------------------------------------------------------------ */

.features {
    counter-reset: feature;
    display: flex;
    column-gap: 4rem;
    
    @include breakpoint-down(mobile) {
        flex-wrap: wrap;
        row-gap: 4.3rem;
    }
}

.feature {
    max-width: 23.1%;
    counter-increment: feature;

    @include breakpoint-down(large-desktop) {
        max-width: 33.33%;
    }

    @include breakpoint-down(mobile) {
        max-width: 100%;
    }

    p {
        letter-spacing: -0.015rem;
    }

    h5 {
        margin-bottom: 2.4rem;

        @include breakpoint-down(mobile) {
            font-size: 1.8rem;
            margin-bottom: 2.8rem;
        }
    }

    .feature__icon {
        display: block;
        width: 4.3rem;
        height: 4.3rem;
        border-radius: 50%;
        border: 0.2rem solid $white;
        text-align: center;
        margin-bottom: 2.2rem;

        @include breakpoint-down(mobile) {
           // border: none;
            text-align: center;
            margin-bottom: 2.2rem;
            width: 3.2rem;
			height: 3.2rem;
        }

        &:before {
            content: counter(feature);
            line-height: 3.7rem;
            
            @include breakpoint-down(mobile) {
                //content: counter(feature,decimal-leading-zero);
                line-height: 3.2rem;             
            }
        }
    }
}