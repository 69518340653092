
.grid-debug {
  .row {
    > .col,
    > [class^="col-"] {
      padding-top: .75rem;
      padding-bottom: .75rem;
      background-color: rgba(86, 61, 124, .15);
      border: 1px solid rgba(86, 61, 124, .2);
    }
  }

  .row + .row {
    margin-top: 1rem;
  }

  .flex-items-top,
  .flex-items-middle,
  .flex-items-bottom {
    min-height: 6rem;
    background-color: rgba(255, 0, 0, .1);
  }
}
