/* ------------------------------------------------------------ *\
    List logos
\* ------------------------------------------------------------ */

.ticker {    
    .ticker__inner {
        animation: ticker var(--animation-duration, 100s) linear infinite;
        margin: 0 -.6rem;
		white-space: nowrap;
        display: flex;
        align-items: center;
       
    }

    img {
        padding: 0 1.6rem;
        display: inline-block;

        @include breakpoint-down(mobile) {
            max-width: 15rem;
        }
    }
}

@keyframes ticker {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }