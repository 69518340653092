/* ------------------------------------------------------------ *\
    Section alt
\* ------------------------------------------------------------ */

.section-alt {
    background-color: $white;
    color: $black;

    .section__inner {
        border-top: 0.1rem solid $black;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1.1rem 0 10.4rem;

        @include breakpoint-down(mobile) {
            padding-bottom: 7.2rem;
        }
    }

    .section__content {
        max-width: 32.7%;
        flex: 0 0 32.7%;

        @include breakpoint-down(small-desktop) {
            max-width: 45%;
            flex: 0 0 45%;
        }

        @include breakpoint-down(mobile) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .section__head {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include breakpoint-down(mobile) {
            //flex-direction: column;
        }
        
         h5{
	        @include breakpoint-down(mobile) {
	        	flex: 1 1 auto;
	        	margin-bottom: 1rem;
	        }	
        }
    }

    .section__image {
        max-width: 49.46%;
        flex: 0 0 49.46%;
        margin-bottom: 0;

        @include breakpoint-down(mobile) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .section__image a {
        display: block;
    }

    .section__image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .btn {
        margin-top: 10.8rem;

        @include breakpoint-down(mobile) {
            margin-top: 3rem;
            margin-bottom: 2rem;
        }
    }

    .title {
        @include breakpoint-down(mobile) {
            margin-bottom: 1rem;
        }
    }
}