/* ------------------------------------------------------------ *\
    Intro
\* ------------------------------------------------------------ */

.intro {
    position: relative;
    height: 100svh;

    .intro__arrow {
        position: absolute;
        left: 50%;
        bottom: 1.2rem;
        transform: translateX(-50%);

        @include breakpoint-down(mobile) {
            display: none;
        }
    }

    .intro__background {
        position: relative;
        width: 100%;
        height: 100%;               
    }

    .intro__background img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .intro__background--left img {
        @include breakpoint-down(mobile) {
            object-position: 15% center;
        }
    }

    .intro__title {
        position: absolute;
        bottom: 3rem;
        left: 1.4rem;
        right: 1.4rem;

        @include breakpoint-down(mobile) {
            bottom: 2.2rem;
            left: 1rem;
            right: 1rem;
        }
    }
}

// Intro alt

.intro--alt {
    .intro__arrow {
        right: 3rem;
        left: auto;
        transform: translateX(0);
    }
}