/* ------------------------------------------------------------ *\
    Typography
\* ------------------------------------------------------------ */


@font-face {
	font-family: 'Maison Neue';
	src: url('../fonts/MaisonNeue-Medium.woff2') format('woff2'),
		 url('../fonts/MaisonNeue-Medium.woff') format('woff'),
		 url('../fonts/MaisonNeue-Medium.ttf') format('truetype');
	font-weight:  600;
	font-style:   normal;
	font-stretch: normal;
}
@font-face {
	font-family: 'Maison Neue';
	src: url('../fonts/MaisonNeue-Book.woff2') format('woff2'),
		 url('../fonts/MaisonNeue-Book.woff') format('woff'),
		 url('../fonts/MaisonNeue-Book.ttf') format('truetype');
	font-weight:  400;
	font-style:   normal;
	font-stretch: normal;
}
@font-face {
	font-family: 'Maison Neue';
	src: url('../fonts/MaisonNeue-Light.woff2') format('woff2'),
		 url('../fonts/MaisonNeue-Light.woff') format('woff'),
		 url('../fonts/MaisonNeue-Light.ttf') format('truetype');
	font-weight:  300;
	font-style:   normal;
	font-stretch: normal;
}

