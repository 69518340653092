/* ------------------------------------------------------------ *\
    Testimonials
\* ------------------------------------------------------------ */

.testimonial {
    text-align: center;

    @include breakpoint-down(tablet) {
        padding: 0 2rem;
    }

    @include breakpoint-down(mobile) {
        padding: 13.9rem 3.4rem 14.3rem;
    }

    blockquote {
        font-size: 3.2rem;
        line-height: 1.12;
        letter-spacing: -.015rem;
        max-width: 65.2rem;
        margin: 0 auto 2.4rem;

        @include breakpoint-down(tablet) {
            font-size: 2rem;
        }
        
        @include breakpoint-down(mobile) {
            font-size: 1.8rem;
            line-height: 1.1;
            margin-bottom: 3.2rem;
        }
    }

    p {
        font-size: 2rem;
        letter-spacing: .06rem;

        @include breakpoint-down(tablet) {
            font-size: 1.4rem;
        }
    }
}