/* ------------------------------------------------------------ *\
    Section welcome
\* ------------------------------------------------------------ */

.section-welcome {
    padding: 2.4rem 0;
    min-height: 110.6rem;
    
    h1{
	     max-width: 185rem;
	     margin-bottom: 15rem;
    }
   
    h3{
	     max-width: 185rem;
    }

    @include breakpoint-down(small-desktop) {
        min-height: auto;
        padding-bottom: 5rem;
    }

    @include breakpoint-down(mobile) {
        padding: 4.5rem 0;
        min-height: 59.9rem;
    }

    .btn {
        margin-left: .8rem;
    }
}

// Section welcome alt

.section-welcome--alt {
    min-height: 89.6rem;
    padding: 3.2rem 0;

    @include breakpoint-down(small-desktop) {
        min-height: auto;
        padding-bottom: 5rem;
    }

    @include breakpoint-down(mobile) {
        padding: 2.2rem 0 6.9rem;
    }

    .section__content {
        margin-top: 11.4rem;
        max-width: 93rem;
        margin-left: auto;
        column-count: 2;
        column-gap: 2.8rem;

        @include breakpoint-down(small-desktop) {
            max-width: 100%;
        }

        @include breakpoint-down(tablet) {
            margin-top: 7.6rem;
        }

        @include breakpoint-down(mobile) {
            column-count: 1;
        }
    }
}