/* ------------------------------------------------------------ *\
    Title
\* ------------------------------------------------------------ */

.title {
    text-transform: uppercase;
    padding-left: 3.3rem;
    position: relative;

    @include breakpoint-down(mobile) {
        padding-left: 2.3rem;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        background-color: $white;
        margin: auto 0;

        @include breakpoint-down(mobile) {
            width: 1.2rem;
            height: 1.2rem;
        }
    }
}

// Title Black

.title--black {
    &:before {
        background-color: $black;
    }
}