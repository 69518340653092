/* ------------------------------------------------------------ *\
    Header
\* ------------------------------------------------------------ */

.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 5;
    padding: 2.1rem 0;
    transition: background-color $t-duration;

    @include breakpoint-down(mobile) {
        padding: 1.5rem 0;
    }

    .header__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        @include breakpoint-down(mobile) {
	        position: relative;
			justify-content: flex-end;

	    }   	  
        
        
    }

    .header__logo {
        width: 7.8rem;
        height: 2.3rem;
        display: inline-block;
        opacity: 1;
        
        @include breakpoint-down(mobile) {
        
        	position: absolute;
			top: 0;
			left: 0;
        }
    }
    
    .header__logo svg {
        width: 100%;
        height: 100%;
        display: inline-block;
    }

    .header__aside {
        @include breakpoint-down(mobile) {
            position: fixed;
            top: var(--header-height);
            left: 0;
            right: 0;
            width: 100%;
            height: calc(100vh - var(--header-height));
            background-color: $black;
            opacity: 0;
            visibility: hidden;
            transition: top $t-duration, opacity $t-duration, visibility $t-duration;
			overflow: hidden;
            // &.is-active {
            //     //top: var(--header-height);
            //     opacity: 1;
            //     visibility: visible;
            // }
        }
    }

    &.is-fixed {
        position: fixed;
        background-color: $black;
        
        .header__logo{
		    opacity: 1;
	    }
        
    }
    
    &.hero{
	    
	    
	    .header__logo{
		    opacity: 1;
	    }
    }

    .nav-trigger {
        opacity: 0;
        visibility: hidden;
    }

    &.is-fixed .nav-trigger {
        opacity: 1;
        visibility: visible;
    }

    &.is-fixed .header__aside.is-active {
        @include breakpoint-down(mobile) {
            opacity: 1;
            visibility: visible;
            z-index: 99;
            
            
            .menu-btn{
	            display: none;
            }
        }
    }
}

// Header home

.header--home {
    position: fixed;

    .header__logo {
        width: 45.5rem;
        height: 13.1rem;
        //transform: scale(6) translateY(-.1rem);
        transform-origin: top left;
        display: inline-block;
        transition: transform .4s cubic-bezier(.23,1,.32,1);
        position: absolute;
        top: 0;
        left: 0;
        
        // @include breakpoint-down(tablet) {
        //     transform: scale(6) translateY(-.1rem);            
        // }
        
        // @include breakpoint-down(mobile) {
        //     transform: scale(4.8) translateY(-.1rem);            
        // }

        @include breakpoint-down(mobile) {
            width: 100%;
            height: auto;
        }
    }

    .header__inner {
        position: relative;
        justify-content: flex-end;
    }

    .header__aside {
        opacity: 0;
        visibility: hidden;
        transition: opacity $t-duration, visibility $t-duration;        
    }
    
    &.is-active .header__logo {
        //transform: scale3d(.175,.175,.175);        
    }

    &.is-active {
        padding: 2rem 0;
    }

    &.is-fixed .header__aside {
        opacity: 1;
        visibility: visible;
    }

    &.is-fixed .header__aside {
        @include breakpoint-down(mobile) {
            opacity: 0;
            visibility: hidden;
            
        }
    }

}

// Header white

.header--white {
    .header__aside {
        @include breakpoint-down(mobile) {
            background-color: $white;
        }
    }
    
    &.is-fixed {
        background-color: $white;
        color: $black;
    }

    &.is-fixed .header__logo svg path {
        fill: $black;
    }
}