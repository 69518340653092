/* ------------------------------------------------------------ *\
    Button
\* ------------------------------------------------------------ */

.btn {
    font-size: 0.9rem;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    padding: 1.9rem 2.6rem 1.9rem 2.2rem;
    border: 0.1rem solid $c-grey-1;
    background-color: transparent;
    font-size: 1.8rem;
    line-height: 1.2;
    letter-spacing: .18rem;
    transition: color $t-duration, background-color $t-duration;
    border-radius: 5px;
    
    &.talk{
	    border: 0.1rem solid $c-grey-3; 
	    padding: 2.11rem 3.0rem 1.8rem 2.6rem;
	    
	    svg {
		    margin-top: -2px;
		}    
	    
    }

    @include breakpoint-down(tablet) {
        font-size: 1.4rem;
        padding: 1.1rem 2.3rem;
        letter-spacing: .12rem;        
    }

    svg {
        display: inline-block;
        vertical-align: middle;
        margin-left: 3.6rem;
        transition: transform 0.3s ease;

        @include breakpoint-down(tablet) {
            max-width: 1.9rem;
            margin-left: 2.6rem;
        }
    }

    svg path {
        transition: fill $t-duration;
    }

    &:hover {
        text-decoration: none;
        
         svg {
	         
	         transform: translateX(10px);
	    }     
    }
    
    @include breakpoint-down(tablet) {
        &:focus,
        &:active {
            text-decoration: none;            
        }
    }
}

// Button white 

.btn--white {
    @media (hover: hover) {
        &:hover {
            text-decoration: none;
            color: $c-grey-2;
            
            svg path {
                fill: $c-grey-2;                
            }
        }
    }
    
    @include breakpoint-down(tablet) {
        &:focus,
        &:active {
            text-decoration: none;            
        }
    }
}