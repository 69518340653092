// Variables

// Colors
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
$white:    #fff !default;
$c-grey-1: #D9D9D9 !default;
$c-grey-2: #A1A1A1 !default;
$c-grey-3: #999 !default;
$c-grey-4: #EBEBEB !default;

// Font Defaults
$font-family-sans-serif:      'Maison Neue', "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$font-family-base:            $font-family-sans-serif;
$font-size-base:              2.1rem !default; // Assumes the browser default, typically `16px`

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.14 !default;

// Body
$body-bg:                   $black !default;
$body-color:                $white !default;

// Links
$link-color:                inherit !default;
$link-decoration:           none !default;
$link-hover-color:          inherit !default;
$link-hover-decoration:     underline !default;

// Transition Duration
$t-duration                 : 0.3s;

// Base Container
$container-max-width        : 188.0rem;
$container-gutter           : 2rem;

// Breakpoints
$breakpoints: (
	mobile-small: 374px,
	mobile-medium: 575px,
	mobile: 767px,
	admin-bar: 782px,
	tablet: 1023px,
	small-desktop: 1199px,
	large-desktop: 1399px
);