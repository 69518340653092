/* ------------------------------------------------------------ *\
    Section default
\* ------------------------------------------------------------ */

.section-default {
    padding-bottom: 10rem;
    min-height: 84rem;
    
    
    
    &.collaborators{
	    
	    background-color: $white;
		color: $black;
		padding-bottom: 10rem;
		min-height: 44rem;
		
		@include breakpoint-down(mobile) {
			min-height: 24rem;
		}	 
		 
    }
    
     &.callout{
	    
	    background-color: $c-grey-4;
		color: $black;
		padding: 7.5rem 0rem;
		min-height: 14rem;
		text-align: center;
		width: 100%;
		
		@include breakpoint-down(mobile) {
			min-height: 10rem;
			text-align: left;
			padding: 4.5rem 0rem 5.5rem 0rem;
		}	 
		
		
		h4{
			font-size: 4.8rem;
			line-height: 6.8rem;	
			letter-spacing: -0.04em;
			font-weight: 400;
			margin-bottom: 3.6rem;
			@include breakpoint-down(mobile) {
				font-size: 2.1rem;
				line-height: 2.4rem;
				max-width: 70%;
				margin-bottom: 4rem;	
				
			}	
			
		}
		 
    }

    @include breakpoint-down(small-desktop) {
        min-height: auto;
        padding-bottom: 7rem;
    }

    @include breakpoint-down(mobile) {
        padding-bottom: 13rem;
    }

    .section__head {
        margin-bottom: 9.5rem;

        @include breakpoint-down(tablet) {
            margin-bottom: 6.6rem;
        }

        @include breakpoint-down(mobile) {
            margin-bottom: 5.1rem;
            
        }
        
        h5{
	        @include breakpoint-down(mobile) {
	        	flex: 1 1  auto;
	        	margin-bottom: 1rem;
	        }	
        }
    }

    .section__head--alt {
        margin-bottom: 3.5rem;
        
         h5{
	        @include breakpoint-down(mobile) {
	        	flex: 1 1 auto;
	        	margin-bottom: 1rem;
	        }	
        }
    }

    .section__inner {
        padding: 0.9rem 0 0;
        //border-top: 0.1rem solid $c-grey-3;
		position: relative;
        @include breakpoint-down(mobile) {
            padding-top: 0.6rem;
        }
    }
    
    .section__inner::before {
			  content: '';
			  position: absolute;
			  top: 0;
			  left: 0;
			  width: 0;
			  height: 0.1rem; /* adjust to your needs */
			  background-color: $c-grey-3; /* your keyline color */
			  transition: width 1.2s ease-in-out;
			}
			
			.section__inner.animate::before {
			  width: 100%;
			}

    .section__image {
        max-width: 49.4%;
        margin-left: auto;
        margin-top: 16rem;

        @include breakpoint-down(small-desktop) {
            margin-top: 11.2rem;
        }

        @include breakpoint-down(tablet) {
            margin-top: 8rem;
        }

        @include breakpoint-down(mobile) {
            max-width: 100%;
            margin-top: 12.6rem;
        }
    }

    .section__image img {
        display: block;
        width: 100%;
    }

    .ticker {
        margin-top: 22.4rem;

        @include breakpoint-down(small-desktop) {
            margin-top: 15.6rem;
        }

        @include breakpoint-down(tablet) {
            margin-top: 13rem;
        }
    }
    
    &.black{
	    
	    .ticker {
        margin-top: 11.4rem;

        @include breakpoint-down(small-desktop) {
            margin-top: 10.6rem;
        }

        @include breakpoint-down(tablet) {
            margin-top: 8rem;
        }
    }
	    
	    
    }
    
}

// Section default alt

.section-default--alt {
    @include breakpoint-down(mobile) {
        padding-bottom: 10.5rem;
    }

    .section__content {
        max-width: 66.3%;
        
        @include breakpoint-down(tablet) {
            max-width: 100%;
        }
    }

    .section__head {
        @include breakpoint-down(mobile) {
            margin-bottom: 6rem;
        }
    }
    
    .section__content--large {
        max-width: 70%;
        
        @include breakpoint-down(mobile) {
            max-width: 100%;            
        }
    }
}