/* ------------------------------------------------------------ *\
    Section project
\* ------------------------------------------------------------ */

.section-project {
    padding: 1.6rem 0 12.1rem;
    background-color: $white;
    color: $black;

    @include breakpoint-down(mobile) {
        padding: 2.4rem 0 8rem;
    }

    .section__head {
        max-width: 92.5%;
        margin-bottom: 16.8rem;

        @include breakpoint-down(tablet) {
            max-width: 100%;
            margin-bottom: 11.76rem;
        }

        @include breakpoint-down(mobile) {
            margin-bottom: 3.5rem;
        }
    }

    .list-info {
        max-width: 49.6%;
        margin-bottom: 9.6rem;

        @include breakpoint-down(tablet) {
            max-width: 100%;
            margin-bottom: 6.7rem;
        }

        @include breakpoint-down(mobile) {
            margin-bottom: 4.1rem;
        }
    }

    .section__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .section__row--reverse {
        flex-direction: row-reverse;
    }
    
    .section__row--reverse-mobile {
        @include breakpoint-down(mobile) {
            flex-direction: column-reverse;            
        }
    }

    .section__row--right {
        justify-content: flex-end;
    }

    .section__row--center {
        justify-content: center;
    }

    .section__row + .section__row {
        margin-top: 20rem;

        @include breakpoint-down(small-desktop) {
            margin-top: 16rem;
        }

        @include breakpoint-down(tablet) {
            margin-top: 11.2rem;
        }
        

        @include breakpoint-down(mobile) {
            margin-top: 1rem;
        }
    }

    .section__image {
        max-width: 100%;
        flex: 0 0 100%;
        position: relative;
        //padding-bottom: 63.8%;
        margin-bottom: 0;
    }

    .section__image--size-1 {
        max-width: 83.1%;
        flex: 0 0 83.1%;
        
        @include breakpoint-down(mobile) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .section__image--size-2 {
        max-width: 49.46%;
        flex: 0 0 49.46%;
        //padding-bottom: 34.1%;

        @include breakpoint-down(mobile) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .section__image--size-3 {
        max-width: 74.7%;
        flex: 0 0 74.7%;
        //padding-bottom: 34.1%;

        @include breakpoint-down(mobile) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
    
     .section__image--size-4 {
        max-width: 42.0%;
        flex: 0 0 42%;
        margin-right: 20px; // Adds space to the right of each item
        
        @include breakpoint-down(mobile) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-right: 0;
            margin-bottom: 10px;
            
            
        }

		&:last-child {
        	margin-right: 0; // Removes the margin from the last item
        	
        	@include breakpoint-down(mobile) {
	        	margin-bottom: 0px;
	        }	
		}
       

       
    }
    

    .section__image img {
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .section__row h2 {
        max-width: 61%;
        flex: 0 0 61%;
        margin-top: 0.6rem;

        @include breakpoint-down(large-desktop) {
            max-width: 90%;
            flex: 0 0 90%;
        }

        @include breakpoint-down(small-desktop) {
            max-width: 100%;
            flex: 0 0 100%;
        }

        @include breakpoint-down(mobile) {
            margin-top: 1.9rem;
            margin-bottom: 16.5rem;
        }
    }

    .testimonial {
        max-width: 49%;
        flex: 0 0 49%;

        @include breakpoint-down(tablet) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}

// Section project secondary

.section-project--secondary {
    padding: 2.3rem 0 16.7rem;

    .section__head {
        max-width: 91.5%;
        margin-bottom: 10.4rem;
        
        @include breakpoint-down(tablet) {
            max-width: 100%;
            margin-bottom: 11.76rem;
        }

        @include breakpoint-down(mobile) {
            margin-bottom: 3.5rem;
        }
    }

    .section__row h2 {
        margin-top: 2rem;
        max-width: 65%;
        flex: 0 0 65%;
        
        &.pt50{
	      margin-top: 15rem;  
	      
	      	@include breakpoint-down(mobile) {
            	margin-top: 3.9rem;
				margin-bottom: 4.5rem;
        	}
	      
        }

        @include breakpoint-down(large-desktop) {
            max-width: 90%;
            flex: 0 0 90%;
        }

        @include breakpoint-down(small-desktop) {
            max-width: 100%;
            flex: 0 0 100%;
        }

        @include breakpoint-down(mobile) {
            margin-top: 1.9rem;
            margin-bottom: 16.5rem;
        }
    }

    .list-info {
        margin-bottom: 8.6rem;

        @include breakpoint-down(tablet) {
            margin-bottom: 6.7rem;
        }

        @include breakpoint-down(mobile) {
            margin-bottom: 4.1rem;
        }
    }
}