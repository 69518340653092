/* ------------------------------------------------------------ *\
    Footer
\* ------------------------------------------------------------ */

.footer {
    background-color: $white;
    color: $black;
    padding: 2.4rem 0 2.3rem;
    font-size: 1.8rem;
    letter-spacing: -.015rem;
    
    @include breakpoint-down(mobile) {
/*
        background-color: #1B1B1B;
        color: $white;
*/
        font-size: 1.4rem;
        padding: 4.2rem 0 3.4rem;
    }

    .footer__logo {
        display: inline-block;
    }

    .footer__logo svg {
        @include breakpoint-down(mobile) {
            max-width: 12.4rem;
            height: auto;
        }
    }

    .footer__logo svg path {
        @include breakpoint-down(mobile) {
            //fill: $white;
        }
    }

    .footer__cols {
        display: flex;
        justify-content: space-between;
    }

    .footer__cols--alt {
        align-items: flex-end;
    }

    .footer__cols + .footer__cols {
        margin-top: 32.1rem;

        @include breakpoint-down(small-desktop) {
            margin-top: 16rem;
        }

        @include breakpoint-down(tablet) {
            margin-top: 6rem;
        }
    }

    .footer__col--size-1 {
        max-width: 24.2%;
        flex: 0 0 24.2%;

        @include breakpoint-down(mobile) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .footer__col--size-2 {
        max-width: 30.5%;
        flex: 0 0 30.5%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include breakpoint-down(small-desktop) {
            max-width: 45%;
            flex: 0 0 45%;
        }

        @include breakpoint-down(tablet) {
            max-width: 55%;
            flex: 0 0 55%;
        }

        @include breakpoint-down(mobile) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
    
    .footer__col--size-3 {
        max-width: 11.5%;
        flex: 0 0 11.5%;

        @include breakpoint-down(tablet) {
            max-width: 25%;
            flex: 0 0 25%;
        }

        @include breakpoint-down(mobile) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .footer__col--size-4 {
        max-width: 50%;
        flex: 0 0 50%;

        @include breakpoint-down(mobile) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .footer__col--size-5 {
        max-width: 38%;
        flex: 0 0 38%;

        @include breakpoint-down(mobile) {
            max-width: 100%;
            flex: 0 0 100%;
        }
    }

    .footer__col--size-4 + .footer__col--size-5 {
        @include breakpoint-down(mobile) {
            margin-top: 3.0rem;
            padding-top: 3rem;
            border-top: 0.1rem solid #999;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                width: 100vw;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;
                height: 0.1rem;
                
            }
        }
    }
    
    .footer__col--size-5 {
	    
	    &.show-mobile{
		    display: none;
		    
		    @include breakpoint-down(mobile) {
            	margin-top: 3.0rem;
				padding-top: 2rem;
				border-top: 0.1rem solid #999;
				position: relative;
				display: block;
			}   
			
			p.credits{
				@include breakpoint-down(mobile) {
					float: right;	
				}	
				
			} 
        
    	}
    }	  
    
    
}