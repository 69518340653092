/* ------------------------------------------------------------ *\
  Shell
\* ------------------------------------------------------------ */

.shell {
	--shell-max-width: 188.0rem;
	--shell-gutter: 2rem;
	width: 100%;
	max-width: calc(var(--shell-max-width) + var(--shell-gutter) * 2);
	padding-inline: var(--shell-gutter);
	margin-inline: auto;
	
	@include breakpoint-down(tablet) {
		--shell-gutter: 1rem; 
		 
	}	 
	
	&.shell--no-gutters {
		--shell-gutter: 0rem;
	}
}
