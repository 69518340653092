/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

html {
	font-size: 62.5%;
	scroll-behavior: auto !important;
	overscroll-behavior: none;

	&[class] {
		margin: 0 !important;
	}
	&.no-scroll{
		overflow-y: hidden;
		height: 100%;
	}
}

body {
	--admin-bar-height: 0px;
	--header-height-visible: var(--header-height, 0);

	min-width: 320px;
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-base;
	font-size: $font-size-base;
	line-height: $line-height-base;
	background-color: $body-bg;
	color: $body-color;
	font-weight: 400;
	letter-spacing: -.015rem;

	@include breakpoint-down(mobile) {
		font-size: 1.8rem;
		line-height: 1.11;
		
		.no-scroll{
			overflow-y: hidden;
			height: 100%;
		}
	}
	
	
}

#content {
	overflow: hidden;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
	margin-bottom: calc(#{$line-height-base}em / 2);
	font-weight: 400;
}

.h1,
h1 {
	font-size: 9rem;
	line-height: 1.11;
	letter-spacing: -.03rem;

	@include breakpoint-down(large-desktop) {
		font-size: 7.2rem;
	}

	@include breakpoint-down(small-desktop) {
		font-size: 5.04rem;
	}

	@include breakpoint-down(tablet) {
		font-size: 3.5rem;
	}

	@include breakpoint-down(mobile) {
		font-size: 3.2rem;
		line-height: 1.06;
	}
}

.h2,
h2 {
	font-size: 6.4rem;
	letter-spacing: -.02rem;
	line-height: 1.12;

	@include breakpoint-down(large-desktop) {
		font-size: 5.6rem;
	}

	@include breakpoint-down(small-desktop) {
		font-size: 3.9rem;
	}

	@include breakpoint-down(tablet) {
		font-size: 2.73rem;
	}

	@include breakpoint-down(mobile) {
		font-size: 2.4rem;
	}
}

.h3,
h3 {
	font-size: 4.8rem;
	line-height: 1.125;
	letter-spacing: -.02rem;

	@include breakpoint-down(small-desktop) {
		font-size: 3.36rem;
	}

	@include breakpoint-down(tablet) {
		font-size: 2.3rem;
	}
}

.h4,
h4 {
	
}

.h5,
h5 {
	font-size: 1.8rem;
	line-height: 1.1;
	letter-spacing: .18rem;

	@include breakpoint-down(tablet) {
		font-size: 1.6rem;
		letter-spacing: .12rem;
	}

	@include breakpoint-down(mobile) {
		font-size: 1.2rem;
	}
}

.h6,
h6 {
	font-size: 1.4rem;
	line-height: 1.7;
	letter-spacing: .1rem;

	@include breakpoint-down(mobile) {
		font-size: 1rem;
		line-height: 2.4;
	}
}

p,
ul:not([class]),
ol,
dl,
hr,
table,
blockquote {
	margin-bottom: #{$line-height-base}em;
}

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
	margin-bottom: 0;
}

.js-change-cursor a {
	cursor: none !important;
}