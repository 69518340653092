/* ------------------------------------------------------------ *\
    List info
\* ------------------------------------------------------------ */

.list-info {
    h6 {
        margin-bottom: .2rem;

        @include breakpoint-down(mobile) {
            margin-bottom: .3rem;
        }
    }

    ul {
        list-style-type: none;
        padding-left: 0;
    }

    li {
        border-top: 0.1rem solid $black;
        font-size: 1.6rem;
        letter-spacing: -.015rem;
        line-height: 1.5;
        column-count: 2;
        padding: 0.4rem 0 0.3rem;

        &:last-child {
            border-bottom: 0.1rem solid $black;

            @include breakpoint-down(mobile) {
                border-color: #c3c3c3;       
            }
        }
        
        @include breakpoint-down(mobile) {
            font-size: 1.2rem;
            line-height: 1.25;
            border-color: #c3c3c3;
            padding: 0.7rem 0 .6rem;
            column-gap: 0;  
        }
    }

    address,
    p {
        margin-bottom: 0;
    }
}