/* ------------------------------------------------------------ *\
    Nav trigger
\* ------------------------------------------------------------ */

.nav-trigger {
   
    
    width: 6rem;
    height: 1.5rem;
    display: none;
    position: relative;
    font-size: 1.2rem;
    letter-spacing: .18rem;
    line-height: 1.6rem;
    text-align: right;
    margin-right: 2rem;

    @include breakpoint-down(mobile) {
        display: inline-block;
        letter-spacing: .12rem;
    }
    
    

    span {
        width: 100%;
        height: 0.2rem;
        background-color: $white;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transition: top $t-duration, transform $t-duration, opacity $t-duration;
    }

    span:first-child {
        top: 0;
    }

    span:nth-child(2) {
        top: 0.75rem;
    }

    span:last-child {
        top: 1.5rem;
    }

    &.is-active span:first-child {
        top: 0.75rem;
        transform: rotate(45deg);
    }

    &.is-active span:nth-child(2) {
        opacity: 0;
    }

    &.is-active span:last-child {
        top: 0.75rem;
        transform: rotate(-45deg);
    }
    
    &:hover{
	    text-decoration: none!important;  
    }
}

// Nav trigger black

.nav-trigger--black {
    span {
        background-color: $black;
    }
}

.mobile-menu-btn{
	font-size: 1.2rem;
    letter-spacing: .18rem;
    line-height: 1.6rem;
    background: #dddddd;
    color: #000;
    border-radius: 5px;
    padding: 5px 10px;
    text-transform: uppercase;
}
.mobile-menu-btn{
	display: none;
}



.mobile-menu-open-btn{
	font-size: 1.8rem!important;
    letter-spacing: .18rem;
    line-height: 6.4rem!important;
    background: transparent;
    color: #fff;
    border-radius: 5px;
    padding: 0px 30px;
    text-transform: uppercase;
    border:1px solid $c-grey-1;
    height: 64px;
    
    svg{
	    margin-left: 50%;
    }
    
}


.header--white{
	
	
	.mobile-menu-open-btn{
	font-size: 1.8rem!important;
    letter-spacing: .18rem;
    line-height: 6.4rem!important;
    background: transparent;
    color: #000;
    border-radius: 5px;
    padding: 0px 30px;
    text-transform: uppercase;
    border:1px solid $c-grey-1;
    height: 64px;
    
    
    
}
	
	
	
	
}


